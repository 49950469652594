import * as React from "react";
import { useNavigate, useHref, useRoutes, useLocation, Link, useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { toast } from "react-toastify";
import { useState, useEffect } from "react";
import { getItem, saveItem, removeItem } from "store/LocalStorage";
import { refreshToken } from "utils/refreshToken";
import axios from "axios";
import { AUTH_URL, API_URL } from "utils/config";
import { getRule } from "utils/rule";
import Loading from "app/components/Dialog/Loading";
import {
  pickTime2,
  backupTime2,
  pathTime,
  time,
  formatTime,
  formatTimeUTC,
} from "utils/moment";
import { TfiReload } from "react-icons/tfi";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import PlayAudio from "app/components/PlayAudio/PlayAudio";
import Stack from "@mui/material/Stack";
import PauseRounded from "@mui/icons-material/PauseRounded";
import PlayArrowRounded from "@mui/icons-material/PlayArrowRounded";
import FastForwardRounded from "@mui/icons-material/FastForwardRounded";
import FastRewindRounded from "@mui/icons-material/FastRewindRounded";
import VolumeUpRounded from "@mui/icons-material/VolumeUpRounded";
import VolumeDownRounded from "@mui/icons-material/VolumeDownRounded";

import { styled } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/MenuItem";
import Checkbox from "@mui/material/Checkbox";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import Card from "@mui/material/Card";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import {createTheme} from "@mui/material";
import SpeedDial from "@mui/material/SpeedDial";
import Slider from "@mui/material/Slider";
import SpeedDialIcon from "@mui/material/SpeedDialIcon";
import SpeedDialAction from "@mui/material/SpeedDialAction";
import InputBase from "@mui/material/InputBase";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import SearchIcon from "@mui/icons-material/Search";
import CallIcon from "@mui/icons-material/Call";
import ContactPhoneIcon from "@mui/icons-material/ContactPhone";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import VolumeDown from "@mui/icons-material/VolumeDown";
import VolumeUp from "@mui/icons-material/VolumeUp";
import CloseIcon from "@mui/icons-material/Close";
import dayjs, { Dayjs } from "dayjs";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { PickersShortcutsItem } from "@mui/x-date-pickers/PickersShortcuts";
import { SingleInputDateTimeRangeFieldProps } from "@mui/x-date-pickers-pro/SingleInputDateTimeRangeField";
import useForkRef from "@mui/utils/useForkRef";
import { DateRange, FieldType } from "@mui/x-date-pickers-pro/models";
import { MobileDateTimeRangePicker, MobileDateTimeRangePickerProps } from "@mui/x-date-pickers-pro/MobileDateTimeRangePicker";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import Popover from "@mui/material/Popover";

import { useDispatch, useSelector } from "react-redux";
import { userInfoSelector } from "app/slice/user/selectors";

import {
  PlayCircleOutline,
  PauseCircleOutline,
  SkipNext,
  SkipPrevious,
  Speed,
} from "@mui/icons-material";
import { convertString } from "utils/convert";

import logo from "assets/images/logo/vinacom360.jpg";

import useWindowDimensions from "app/hook/useWindowDimensions";

const toastOptions: any = {
  position: "bottom-left",
  theme: "light",
};

// @convert price format
const formatCurrency = (number: number) => {
  return number.toLocaleString("vi-VN", { style: "currency", currency: "VND" });
};

export function CdrPage() {
  const pathname = useLocation().pathname;
  const {path} = useParams();
  // console.log('path: ', pathname)
  // console.log('params: ', params)
  const navigate = useNavigate();
  const theme = createTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const userInfo = useSelector(userInfoSelector);

  const [customerID, setCustomerID] = useState<any>("");
  const [CDRIndex, setCDRIndex] = useState<any>("");
  const [viewType, setViewType] = useState<string>("vertical");
  const [title, setTitle] = useState<any>("");
  const [record, setRecord] = useState<string>("");
  const [action, setAction] = useState<any>("");
  const [list, setList] = useState<any>([]);
  const [timeOutStart, setTimeOutStart] = useState<any>();
  const [options, setOptions] = useState<any>({});
  const [optionDownload, setOptionDownload] = useState<any>("");
  const [role, setRole] = useState<any>("");
  const [rule, setRule] = useState<any>([]);
  const [count, setCount] = useState<any>(0);
  const [total, setTotal] = useState<any>(0);
  const [page, setPage] = useState<number>(Number(getItem("page")) || 1);
  const [lastPage, setLastPage] = useState<any>(1);
  const [limit, setLimit] = useState<any>(50);
  const [listAssign, setListAssign] = useState<any>([]);
  const [assignRole, setAssignRole] = useState<any>("");
  const [isCheckedAll, setIsCheckedAll] = useState<boolean>(false);
  const [idTag, setIdTag] = useState<any>(getItem("idTag") || "");
  const [owner, setOwner] = useState<any>(getItem("owner") || "");
  const [dateCDR, setDateCDR] = useState<string>(getItem("dateCDR") || "");
  const [fromDate, setFromDate] = useState<string>(getItem("fromDate") || "");
  const [toDate, setToDate] = useState<string>(getItem("toDate") || "");
  const [valueTimeRange, setValueTimeRange] = React.useState<DateRange<Dayjs>>([null, null]);
  const [migrateFrom, setMigrateFrom] = useState<string>("");
  const [migrateTo, setMigrateTo] = useState<string>("");
  const [params, setParams] = useState<any>({
    _id: "",
    role: "",
    status: "",
    keyword: "",
    salesTag: "",
    teamleadTag: "",
    supervisorTag: "",
    ASMTag: "",
    headTag: "",
    cnum: "",
    dst: "",
    disposition: "",
    fromDate: "",
    toDate: "",
  });
  const [keyword, setKeyword] = useState<any>();
  const [openDrawer, setOpenDrawer] = React.useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isLoadingDialog, setIsLoadingDialog] = useState<boolean>(false);
  const [isLogin, setIsLogin] = useState<boolean>(true);
  const [isFilterTop, setIsFilterTop] = useState<boolean>(
    getItem("isFilterTop") === "false" ? false : true || false
  );
  const [isOpenDownload, setIsOpenDownload] = useState<boolean>(false);
  const [isOpenMigrate, setIsOpenMigrate] = useState<boolean>(false);
  const [isOpenPlay, setIsOpenPlay] = useState<boolean>(false);

  const handleChecked = (id) => {
    const newList = list.map((item) => {
      return {
        ...item,
        checked: id === item._id ? !item.checked : item.checked,
      };
    });
    if (newList.find((item) => item.checked === false)) {
      setIsCheckedAll(false);
    } else {
      setIsCheckedAll(true);
    }
    setList(newList);
  };
  // const refreshToken = () => {
  //   const refreshToken = getItem("refreshToken");
  //   const data = JSON.stringify({ refreshToken });
  //   // console.log(data)
  //   axios
  //     .request({
  //       method: "post",
  //       maxBodyLength: Infinity,
  //       url: `${AUTH_URL}/token`,
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //       data: data,
  //     })
  //     .then(async (res) => {
  //       const data = res.data.data;
  //       saveItem(data);
  //     })
  //     .catch((error) => {
  //       // console.log(error)
  //       toast(`Refresh token failed: ${error.response.message}`, toastOptions);
  //       // handleLogout()
  //     });
  // };

  const resetParams = () => {
    const data = {
      keyword: "",
      _id: "",
      idTag: "",
      role: "",
      status: "",
      salesTag: "",
      teamleadTag: "",
      supervisorTag: "",
      ASMTag: "",
      headTag: "",
      cnum: "",
      dst: "",
      disposition: "",
      fromDate: "",
      toDate: "",
      pathFile: "",
      limit: 20,
      page: 1,
    };
    saveItem({ keyword: "" });
    setKeyword("");
    setPage(1);
    saveItem({ idTag: "" });
    saveItem({ assignRole: "" });
    setAssignRole("");
    saveItem({ owner: "" });
    setOwner("");
    saveItem(data);
    setParams(data);
    saveItem({ dateCDR: "" });
    setDateCDR("");
    setFromDate("");
    setToDate("");
    setOptions({});
    saveItem({ fromDate: "" });
    saveItem({ toDate: "" });
    saveItem({ isFilterTop: false });
  };

  const choseTime = (time: string) => {
    const date = new Date(Date.now());
    const getDate = date.getDate();
    const numberOfDayBefor = date.getDay() === 0 ? 6 : date.getDay() - 1;
    const setHours = date.setHours(7, 0, 0);
    const toDayISO = new Date(setHours).toISOString().slice(0, 19);
    const toDayISOEnd = new Date(setHours + 24 * 60 * 60 * 1000 - 60 * 1000)
      .toISOString()
      .slice(0, 19);
    const lastDayISO = new Date(setHours - 24 * 60 * 60 * 1000)
      .toISOString()
      .slice(0, 19);
    const lastDayISOEnd = new Date(setHours - 60 * 1000)
      .toISOString()
      .slice(0, 19);
    const thisweekISO = new Date(
      setHours - numberOfDayBefor * 24 * 60 * 60 * 1000
    )
      .toISOString()
      .slice(0, 19);
    const thisweekISOEnd = toDayISOEnd;
    const lastweekISO = new Date(
      setHours - (numberOfDayBefor + 7) * 24 * 60 * 60 * 1000
    )
      .toISOString()
      .slice(0, 19);
    const lastweekISOEnd = new Date(
      setHours - numberOfDayBefor * 24 * 60 * 60 * 1000 - 60 * 1000
    )
      .toISOString()
      .slice(0, 19);
    const thisMonthISO = new Date(
      setHours - (getDate - 1) * 24 * 60 * 60 * 1000
    )
      .toISOString()
      .slice(0, 19);
    const thisMonthISOEnd = toDayISOEnd;
    const lastmonthISO = new Date(
      setHours - (getDate - 1 + 30) * 24 * 60 * 60 * 1000
    )
      .toISOString()
      .slice(0, 19);
    const lastmonthISOEnd = new Date(
      setHours - (getDate - 1) * 24 * 60 * 60 * 1000 - 60 * 1000
    )
      .toISOString()
      .slice(0, 19);

    // console.log({date, toDayISO, setHours, numberOfDayBefor, getDate})
    if (!time) {
      setParams({ ...params, fromDate: "", toDate: "" });
      setFromDate("");
      setToDate("");
      saveItem({ fromDate: "" });
      saveItem({ toDate: "" });
    } else if (time === "today") {
      setParams({
        ...params,
        fromDate: toDayISO,
        toDate: toDayISOEnd,
      });
      setFromDate(toDayISO);
      setToDate(toDayISOEnd);
      saveItem({ fromDate: toDayISO, toDate: toDayISOEnd });
    } else if (time === "yesterday") {
      setParams({
        ...params,
        fromDate: lastDayISO,
        toDate: lastDayISOEnd,
      });
      setFromDate(lastDayISO);
      setToDate(lastDayISOEnd);
      saveItem({ fromDate: lastDayISO, toDate: lastDayISOEnd });
    } else if (time === "thisweek") {
      setParams({
        ...params,
        fromDate: thisweekISO,
        toDate: thisweekISOEnd,
      });
      setFromDate(thisweekISO);
      setToDate(thisweekISOEnd);
      saveItem({ fromDate: thisweekISO, toDate: thisweekISOEnd });
    } else if (time === "lastweek") {
      setParams({
        ...params,
        fromDate: lastweekISO,
        toDate: lastweekISOEnd,
      });
      setFromDate(lastweekISO);
      setToDate(lastweekISOEnd);
      saveItem({ fromDate: lastweekISO, toDate: lastweekISOEnd });
    } else if (time === "thismonth") {
      setParams({
        ...params,
        fromDate: thisMonthISO,
        toDate: thisMonthISOEnd,
      });
      setFromDate(thisMonthISO);
      setToDate(thisMonthISOEnd);
      saveItem({ fromDate: thisMonthISO, toDate: thisMonthISOEnd });
    } else if (time === "lastmonth") {
      setParams({
        ...params,
        fromDate: lastmonthISO,
        toDate: lastmonthISOEnd,
      });
      setFromDate(lastmonthISO);
      setToDate(lastmonthISOEnd);
      saveItem({ fromDate: lastmonthISO, toDate: lastmonthISOEnd });
    }
    setIsLoading(!isLoading);
  };

  const toggleDialogExport = () => {
    setIsOpenDownload(!isOpenDownload);
  };
  const toggleDialogMigrate = () => {
    setIsOpenMigrate(!isOpenMigrate);
  };

  const handleAction = (e: any) => {
    setAction(e.target.value);
    setTimeout(() => {
      setAction("");
    }, 1 * 1000);
    if (e.target.value === "exportCDR") {
      toggleDialogExport();
    }
    if (e.target.value === "migrate") {
      toggleDialogMigrate();
    }
  };

  const handleFindRole = (e: any) => {
    // let roleUp = ''
    const getList = (roleUp: string) => {
      const token = localStorage.getItem("TOKEN");
      const configGetUsers = {
        method: "get",
        url: `${AUTH_URL}/user/list?role=${roleUp}&limit=2000`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };
      axios
        .request(configGetUsers)
        .then((res) => {
          setListAssign(res.data.data.users);
          // console.log(res);
          // console.log({ listMananers });
        })
        .catch((error) => {
          toast(error.message, toastOptions);
        });
    };
    // if (e.target.value === "admin") {
    //   getList("admin");
    // }
    // if (e.target.value === "head") {
    //   getList("head");
    // }
    // if (e.target.value === "ASM") {
    //   getList("ASM");
    // }
    // if (e.target.value === "supervisor") {
    //   getList("supervisor");
    // }
    // if (e.target.value === "teamlead") {
    //   getList("teamlead");
    // }
    // if (e.target.value === "sales") {
    //   getList("sales");
    // }
    if (!e.target.value) {
      setListAssign([]);
      setParams({
        ...params,
        salesTag: "",
        teamleadTag: "",
        supervisorTag: "",
      });
      setIsLoading(!isLoading);
    } else {
      getList(e.target.value);
    }
  };

  const handleOnchangeAssign = (v: any) => {
    setIdTag(v);
    // saveItem({ idTag: v });
    if (assignRole === "head") {
      setParams({
        ...params,
        headTag: v,
        ASMTag: "",
        supervisorTag: "",
        teamleadTag: "",
        salesTag: "",
      });
      saveItem({
        ...params,
        headTag: v,
        ASMTag: "",
        supervisorTag: "",
        teamleadTag: "",
        salesTag: "",
      });
    }
    if (assignRole === "ASM") {
      setParams({
        ...params,
        ASMTag: v,
        supervisorTag: "",
        teamleadTag: "",
        salesTag: "",
      });
      saveItem({
        ...params,
        ASMTag: v,
        supervisorTag: "",
        teamleadTag: "",
        salesTag: "",
      });
    }
    if (assignRole === "supervisor") {
      setParams({
        ...params,
        headTag: "",
        ASMTag: "",
        supervisorTag: v,
        teamleadTag: "",
        salesTag: "",
      });
      saveItem({
        ...params,
        headTag: "",
        ASMTag: "",
        supervisorTag: v,
        teamleadTag: "",
        salesTag: "",
      });
    }
    if (assignRole === "teamlead") {
      setParams({
        ...params,
        headTag: "",
        ASMTag: "",
        teamleadTag: v,
        salesTag: "",
      });
      saveItem({
        ...params,
        headTag: "",
        ASMTag: "",
        teamleadTag: v,
        salesTag: "",
      });
    }
    if (assignRole === "sales") {
      setParams({
        ...params,
        headTag: "",
        ASMTag: "",
        teamleadTag: "",
        _id: v,
      });
      saveItem({
        ...params,
        headTag: "",
        ASMTag: "",
        teamleadTag: "",
        _id: v,
      });
    }
    if (!v) {
      setParams({
        ...params,
        _id: "",
        teamleadTag: "",
        supervisorTag: "",
        ASMTag: "",
        headTag: "",
      });
      saveItem({
        ...params,
        _id: "",
        teamleadTag: "",
        supervisorTag: "",
        ASMTag: "",
        headtag: "",
      });
    }
    setIsLoading(!isLoading);
  };

  const handleOwner = (e: any) => {
    setOwner(e.target.value);
    if (!e.target.value) {
      setParams({
        ...params,
        headTag: "",
        ASMTag: "",
        supervisorTag: "",
        teamleadTag: "",
        salesTag: "",
      });
      setAssignRole("");
    }
    if (e.target.value === "null") {
      if (role === "head")
        setParams({
          ...params,
          ASMTag: null,
          supervisorTag: null,
          salesTag: null,
          teamleadTag: null,
        });
      if (role === "ASM")
        setParams({
          ...params,
          supervisorTag: null,
          salesTag: null,
          teamleadTag: null,
        });
      if (role === "supervisor")
        setParams({ ...params, salesTag: null, teamleadTag: null });
      if (role === "teamlead") {
        setParams({ ...params, salesTag: null });
      }
      if (role === "admin" || role === "root") {
        setParams({
          ...params,
          supervisorTag: null,
          teamleadTag: null,
          salesTag: null,
        });
      }
    }
    setIsLoading(!isLoading);
  };

  const handleDownload = () => {
    const token = localStorage.getItem("TOKEN");
    if (optionDownload) {
      toggleDialogExport();
      setIsLoadingDialog(true);
      axios
        .get(
          `${API_URL}/cdr/download?page=${page}&limit=${
            optionDownload === "full" ? total : limit
          }&keyword=${params.keyword}&_id=${params._id}&role=${
            params.role
          }&status=${params.status}&salesTag=${params.salesTag}&teamleadTag=${
            params.teamleadTag
          }&supervisorTag=${params.supervisorTag}&ASMTag=${
            params.ASMTag
          }&headTag=${params.headTag}&cnum=${params.cnum}&dst=${
            params.dst
          }&fromDate=${params.fromDate}&toDate=${params.toDate}&disposition=${
            params.disposition
          }`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
            responseType: "blob",
          }
        )
        .then((res) => {
          setIsLoadingDialog(false);
          const blob: any = res.data;
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.style.display = "none";
          a.href = url;
          // the filename you want
          a.download = `${pathTime(new Date())}-CDR-export.xlsx`;
          document.body.appendChild(a);
          a.click();
          window.URL.revokeObjectURL(url);
          // alert(`File ${get(list[i], 'name')} của bạn đã được tải về!`);
          // reload status
        });
    }
  };

  const handleMigrate = () => {
    const token = localStorage.getItem("TOKEN");
    toggleDialogMigrate();
    setIsLoadingDialog(true);
    axios
      .get(
        `${API_URL}/cdr/migrateCDR?fromDate=${migrateFrom}&toDate=${migrateTo}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        toast.success(res.data.message);
        setIsLoadingDialog(false);
      })
      .catch((error) => {
        console.log("err: ", error);
        toast.error(error.response.data.message, toastOptions);
        setIsLoadingDialog(false);
      });
  };

  //detect view type
  const { width } = useWindowDimensions();
  useEffect(() => {
    if (width < 600) {
      setViewType("gird");
    } else {
      setViewType("vertical");
    }
  }, [width]);

  useEffect(() => {
    setRole(userInfo?.role);
  }, [userInfo]);

  // @ fetch data
  useEffect(() => {
    const token = localStorage.getItem("TOKEN");
    if (!list) setIsLoadingDialog(true);
    const configGetCDR = {
      method: "get",
      url: `${API_URL}/cdr?reverse=1&page=${page}&limit=${limit}&keyword=${params.keyword}&_id=${params._id}&role=${params.role}&status=${params.status}&salesTag=${params.salesTag}&teamleadTag=${params.teamleadTag}&supervisorTag=${params.supervisorTag}&ASMTag=${params.ASMTag}&headTag=${params.headTag}&cnum=${params.cnum}&dst=${params.dst}&dstID=${path || ''}&gteDate=${params.fromDate}&lteDate=${params.toDate}&disposition=${params.disposition}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    axios
      .get(`${AUTH_URL}/user`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        // setRole(res.data.data.role);
        const role = res.data.data.role;
        // console.log('data: ', res.data.data)
        if (res.data.data.permission.default) {
          const findRule = getRule(res.data.data.company.rule[role]);
          setRule(findRule);
          let choseRule: any = [];
          findRule.map((item: any) => {
            if (item === "exportCDR") choseRule.push(item);
          });
          setRule(choseRule);
        } else if (!res.data.data.permission.default) {
          const findRule = getRule(res.data.data.permission.rule);
          setRule(findRule);
          let choseRule: any = [];
          findRule.map((item: any) => {
            if (item === "exportCDR") choseRule.push(item);
          });
          setRule(choseRule);
        }
      })
      .catch((error) => {
        console.log(error);
      });
    axios
      .request(configGetCDR)
      .then((res) => {
        setIsLoadingDialog(false);
        // setRole(res.data.data.role);
        const metaData = res?.data?.data?.data;
        const newList = metaData.map((item) => {
          return { checked: false, ...item };
        });
        setList(newList);
        setCount(res.data.data.count);
        setTotal(res.data.data.total);
        const num1 = Math.trunc(res.data.data.total / parseInt(limit));
        const num2 = res.data.data.total % parseInt(limit);
        const lastPage = num1 + (num2 > 0 ? 1 : 0);
        setLastPage(lastPage);
        // console.log(res);
        // console.log({ list });
      })
      .catch((error) => {
        setIsLoadingDialog(false);
        // console.log(error);
        setList([]);
        if (error.response) {
          if (error.response?.status == 403) {
            const REFRESH_TOKEN = localStorage.getItem('REFRESH_TOKEN') as string
              REFRESH_TOKEN && refreshToken(REFRESH_TOKEN);
          }
        } else {
          toast(error.message, toastOptions);
        }
      });
    // handleFindRole(assignRole)
    const getList = (roleUp: string) => {
      const token = localStorage.getItem("TOKEN");
      const configGetUsers = {
        method: "get",
        url: `${AUTH_URL}/user/list?role=${roleUp}&limit=${1000}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };
      axios
        .request(configGetUsers)
        .then((res) => {
          const dataUsers = res.data.data.users;
          setListAssign(dataUsers);
          const option = dataUsers.find((data: any) => data._id == idTag);
          setOptions(option);
        })
        .catch((error) => {
          // console.log(error);
          if (error.response) {
            if (error.response?.data.message === "jwt expired") {
              const REFRESH_TOKEN = localStorage.getItem('REFRESH_TOKEN') as string
              REFRESH_TOKEN && refreshToken(REFRESH_TOKEN);
              setIsLoading(!isLoading);
            }
          } else {
            toast(error.message, toastOptions);
          }
        });
    };
    if (assignRole === "head") {
      getList("head");
    }
    if (assignRole === "ASM") {
      getList("ASM");
    }
    if (assignRole === "supervisor") {
      getList("supervisor");
    }
    if (assignRole === "teamlead") {
      getList("teamlead");
    }
    if (assignRole === "sales") {
      getList("sales");
    }
  }, [isLoading, path]);

  const shortcutsItems: PickersShortcutsItem<DateRange<Dayjs>>[] = [
    {
      label: "Hôm nay",
      getValue: () => {
        const today = dayjs();
        return [today.startOf("day"), today.endOf("day")];
      },
    },
    {
      label: "Tuần này",
      getValue: () => {
        const today = dayjs();
        return [today.startOf("week"), today.endOf("week")];
      },
    },
    {
      label: "Tuần trước",
      getValue: () => {
        const today = dayjs();
        const prevWeek = today.subtract(7, "day");
        return [prevWeek.startOf("week"), prevWeek.endOf("week")];
      },
    },
    {
      label: "7 ngày trước",
      getValue: () => {
        const today = dayjs();
        return [today.subtract(7, "day"), today];
      },
    },
    {
      label: "Tháng này",
      getValue: () => {
        const today = dayjs();
        return [today.startOf("month"), today.endOf("month")];
      },
    },
    // {
    //   label: "Next Month",
    //   getValue: () => {
    //     const today = dayjs();
    //     const startOfNextMonth = today.endOf("month").add(1, "day");
    //     return [startOfNextMonth, startOfNextMonth.endOf("month")];
    //   },
    // },
    { label: "Reset", getValue: () => [null, null] },
  ];

  const actions = [{ icon: <FilterAltIcon />, name: "Filter" }];

  const handleCheckedAll = () => {
    const newList = list.map((item) => {
      if (isCheckedAll) {
        return { ...item, checked: false };
      } else {
        return { ...item, checked: true };
      }
    });
    setList(newList);
  };

  const more = () => {
    return (
      <Box
        sx={{
          height: 320,
          transform: "translateZ(0px)",
          flexGrow: 1,
          position: "fixed",
          bottom: "50px",
          right: "50px",
          [theme.breakpoints.down("sm")]: {
            // bottom: 40,
            // right: 5,
            display: "none",
          },
        }}
      >
        <SpeedDial
          ariaLabel="SpeedDial basic example"
          sx={{}}
          icon={<SpeedDialIcon />}
          FabProps={{
            sx: {
              bgcolor: "#40f3ab",
              "&:hover": {
                bgcolor: "#40f3ab",
              },
            },
          }}
        >
          {actions.map((action) => (
            <SpeedDialAction
              key={action.name}
              icon={action.icon}
              tooltipTitle={action.name}
              onClick={() => {
                if (action.name === "Filter") setOpenDrawer(true);
              }}
            />
          ))}
        </SpeedDial>
      </Box>
    );
  };

  const DateRangePickerWithButtonField = () => {
    const shortcutsItems: PickersShortcutsItem<DateRange<Dayjs>>[] = [
      {
        label: "Hôm nay",
        getValue: () => {
          const today = dayjs();
          return [today.startOf("day"), today.endOf("day")];
        },
      },
      {
        label: "Tuần này",
        getValue: () => {
          const today = dayjs();
          return [today.startOf("week"), today.endOf("week")];
        },
      },
      {
        label: "Tuần trước",
        getValue: () => {
          const today = dayjs();
          const prevWeek = today.subtract(7, "day");
          return [prevWeek.startOf("week"), prevWeek.endOf("week")];
        },
      },
      {
        label: "7 ngày trước",
        getValue: () => {
          const today = dayjs();
          return [today.subtract(7, "day"), today];
        },
      },
      {
        label: "Tháng này",
        getValue: () => {
          const today = dayjs();
          return [today.startOf("month"), today.endOf("month")];
        },
      },

      { label: "Reset", getValue: () => [null, null] },
    ];
    interface DateTimeRangeButtonFieldProps
      extends SingleInputDateTimeRangeFieldProps<Dayjs> {
      setOpen?: React.Dispatch<React.SetStateAction<boolean>>;
    }

    type DateRangeButtonFieldComponent = ((
      props: DateTimeRangeButtonFieldProps & React.RefAttributes<HTMLDivElement>
    ) => React.JSX.Element) & { fieldType?: FieldType };

    const DateRangeButtonField = React.forwardRef(
      (props: DateTimeRangeButtonFieldProps, ref: React.Ref<HTMLElement>) => {
        const {
          setOpen,
          label,
          id,
          disabled,
          InputProps: { ref: containerRef } = {},
          inputProps: { "aria-label": ariaLabel } = {},
        } = props;

        const handleRef = useForkRef(ref, containerRef);

        return (
          <Button
            variant="outlined"
            id={id}
            disabled={disabled}
            ref={handleRef}
            aria-label={ariaLabel}
            onClick={() => setOpen?.((prev) => !prev)}
            color="success"
            sx={{
              p: 0.9,
              color: 'inherit',
              textTransform: 'none'
            }}
          >
            {label ? `Thời gian từ: ${label}` : "Lọc theo khung thời gian"}
          </Button>
        );
      }
    ) as DateRangeButtonFieldComponent;

    DateRangeButtonField.fieldType = "single-input";

    const ButtonDateRangePicker = React.forwardRef(
      (
        props: Omit<
          MobileDateTimeRangePickerProps<Dayjs>,
          "open" | "onOpen" | "onClose"
        >,
        ref: React.Ref<HTMLDivElement>
      ) => {
        const [open, setOpen] = React.useState(false);

        return (
          <MobileDateTimeRangePicker
            slots={{ field: DateRangeButtonField, ...props.slots }}
            slotProps={{
              field: { setOpen } as any,
              shortcuts: {
                items: shortcutsItems,
              },
              mobilePaper: {
                sx: {
                  bgcolor: (theme) =>
                    theme.palette.mode === "dark" ? "black" : "white",
                }
              }
            }}
            ref={ref}
            {...props}
            open={open}
            onClose={() => setOpen(false)}
            onOpen={() => setOpen(true)}
            value={valueTimeRange}
            onChange={(newValue: any) => {
              // console.log("gte: ", newValue?.[0]?.$d.toString());
              // console.log("lte: ", newValue?.[1]?.$d.toString());
              setValueTimeRange(newValue);
              setParams({
                ...params,
                fromDate: newValue?.[0] || "",
                toDate: newValue?.[1] || "",
              });
              setIsLoading(!isLoading);
            }}
          />
        );
      }
    );
    return (
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <ButtonDateRangePicker
          label={
            valueTimeRange[0] === null && valueTimeRange[1] === null
              ? null
              : valueTimeRange
                  .map((date) =>
                    date ? date.format("DD/MM/YYYY - HH:mm") : "hiện tại"
                  )
                  .join(" - ")
          }
        />
      </LocalizationProvider>
    );
  };

  const filterMenu = () => {
    const statusList = [
      { title: "Đã nghe máy", value: "ANSWERED" },
      { title: "Không nghe máy", value: "NO ANSWER" },
      { title: "Máy bận", value: "BUSY" },
      { title: "Gọi thất bại", value: "FAILED" },

    ];
    return (
      <Card
        sx={{
          width: 300,
          height: 500,
          minWidth: 300,
          minHeight: 500,
          borderRadius: 2,
          mt: "auto",
          p: 2,
          overflow: "auto",
          bgcolor: (theme) =>
            theme.palette.mode === "dark" ? "black" : "white",
        }}
      >
        <InputBase
          sx={{ ml: 1, flex: 1, borderBottom: "0.5px solid green" }}
          autoFocus={true}
          placeholder="Search..."
          inputProps={{ "aria-label": "search google maps" }}
          value={params.keyword}
          onChange={(e) => {
            // setKeyword(e.target.value);
            setParams({ ...params, keyword: e.target.value });
            saveItem({ keyword: e.target.value });
            clearTimeout(timeOutStart)
            setTimeOutStart(
              setTimeout(() => {
                setIsLoading(!isLoading);
              }, 1000)
            );
          }}
        />
        <IconButton type="button" sx={{ p: "10px" }} aria-label="search">
          <SearchIcon />
        </IconButton>
        <Box
          sx={{
            display: "flex",
            width: "100%",
          }}
        >
          <TextField
            type="number"
            size="small"
            inputProps={{ maxLength: 1000 }}
            value={params.limit}
            sx={{
              width: "80px",
              "& .MuiInputBase-input": {
                borderColor: "green",
              },
              "& .MuiOutlinedInput-root": {
                borderColor: "green",
                "& fieldset": {
                  border: 0.5,
                  borderColor: "green",
                },
                "&.Mui-focused fieldset": {
                  border: 0.5,
                  borderColor: "green",
                },
              },
            }}
            onChange={(e) => {
              setLimit(e.target.value);
              setParams({ ...params, limit: e.target.value });
              setTimeOutStart(
                setTimeout(() => {
                  setIsLoading(!isLoading);
                  saveItem({ limit: e.target.value });
                }, 1000)
              );
              // setIsLoading(!isLoading);
            }}
          />
          <Typography sx={{ p: 1 }}>Số lượng lọc</Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            p: 1,
            gap: 1,
            width: "100%",
            borderRadius: 1,
            border: 0.5,
            borderColor: "green",
            mt: 1,
          }}
        >
          <Typography variant="body1" align="center" color="success" sx={{}}>
            Tổng số
          </Typography>
          <Typography variant="body1" align="center" color="success" sx={{}}>
            {count}
          </Typography>
          <Typography variant="body1" align="center" color="success">
            Trên
          </Typography>
          <Typography variant="body1" align="center" color="success" sx={{}}>
            {total}
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            gap: 1,
            mt: 1,
            justifyContent: "space-between",
            alignItems: "center",
            mx: "auto",
          }}
        >
          <Button
            variant="outlined"
            color="success"
            size="small"
            onClick={(e) => {
              setPage(1);
              setParams({ ...params, page: 1 });
              setIsLoading(!isLoading);
              saveItem({ page: 1 });
            }}
          >
            Trang đầu
          </Button>
          <IconButton
            size="small"
            onClick={(e) => {
              setPage(page > 1 ? page - 1 : 1);
              setParams({ ...params, page: page > 1 ? page - 1 : 1 });
              saveItem({ page: page > 1 ? page - 1 : 1 });
              if (page > 1) setIsLoading(!isLoading);
            }}
          >
            <NavigateBeforeIcon />
          </IconButton>
          <Box
            sx={{
              px: 3,
              py: 1,
              borderRadius: "50%",
              backgroundColor: "neutral.900",
            }}
          >
            <Typography variant="body1">{page}</Typography>
          </Box>
          <IconButton
            size="small"
            onClick={(e) => {
              setPage(page < lastPage ? page + 1 : lastPage);
              setParams({
                ...params,
                page: page < lastPage ? page + 1 : lastPage,
              });
              saveItem({ page: page < lastPage ? page + 1 : lastPage });
              if (page < lastPage) setIsLoading(!isLoading);
            }}
          >
            <NavigateNextIcon />
          </IconButton>
          <Button
            variant="outlined"
            color="success"
            size="small"
            onClick={(e) => {
              setPage(lastPage);
              setParams({ ...params, page: lastPage });
              setIsLoading(!isLoading);
              saveItem({ page: lastPage });
            }}
          >
            Trang cuối
          </Button>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 0.5,
          }}
        >
          <Checkbox
            sx={{ color: "green" }}
            checked={isCheckedAll}
            onChange={(e) => {
              setIsCheckedAll(!isCheckedAll);
              handleCheckedAll();
            }}
            inputProps={{ "aria-label": "controlled" }}
          />
          <Typography>Chọn tất cả</Typography>
        </Box>
        <Box
          sx={{
            display: role === "sales" ? "none" : "flex",
            flexDirection: "column",
            gap: 1,
            mt: 1,
            width: "100%",
            border: "0.5px solid green",
            borderRadius: 1,
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              p: 1,
              gap: 1,
            }}
          >
            {/* <Typography
              sx={{
                p: 1,
              }}
            >
              Lọc theo sở hữu
            </Typography> */}
            {/* <TextField
              select
              label="Lọc theo sở hữu"
              size="small"
              value={owner}
              onChange={handleOwner}
              sx={{
                "& .MuiOutlinedInput-root": {
                  padding: "1px",
                  "& fieldset": {
                    // borderColor: "green",
                  },
                  "&:hover fieldset": {
                    borderColor: "green",
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "green",
                    borderWidth: "1px",
                  },
                },
              }}
            >
              <MenuItem value="null">Chưa sở hữu</MenuItem>
              <MenuItem value="owner">Người import</MenuItem>
              <MenuItem value="handler">Người xử lý</MenuItem>
            </TextField> */}
            <TextField
              select
              size="small"
              label="Cấp sở hữu"
              sx={{
                display: "flex",
                "& .MuiOutlinedInput-root": {
                  padding: "1px",
                  "& fieldset": {
                    // borderColor: "green",
                  },
                  "&:hover fieldset": {
                    borderColor: "green",
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "green",
                    borderWidth: "1px",
                  },
                },
              }}
              value={assignRole}
              onChange={(e) => {
                setAssignRole(e.target.value);
                handleFindRole(e);
              }}
            >
              <MenuItem value="admin">Admin</MenuItem>
              <MenuItem value="head">Head</MenuItem>
              <MenuItem value="ASM">ASM</MenuItem>
              <MenuItem value="supervisor">Supervisor</MenuItem>
              <MenuItem value="teamlead">Teamlead</MenuItem>
              <MenuItem value="sales">Sales</MenuItem>
            </TextField>
            <Autocomplete
              id="listAssign"
              size="small"
              options={listAssign}
              getOptionLabel={(option: any) =>
                `${option?.name || ""}${option.name ? " - " : ""}${
                  option?.userName || ""
                }`
              }
              sx={{
                display: assignRole ? "flex" : "none",
                width: "100%",
              }}
              value={{ ...options }}
              onChange={(e, v) => {
                console.log("value: ", v);
                setOptions(v ? v : {});
                handleOnchangeAssign(v?._id);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  sx={{
                    padding: "1px",
                    color: "green",
                    "& .MuiInputBase-input": {
                      // color: "green", // Text color
                      padding: "1px",
                    },
                    // '& .MuiInputBase-root': {
                    //   padding: '1px', // Thiết lập padding là 10px
                    // },
                    "& label.Mui-focused": {
                      // color: "green",
                    },
                    "& .MuiInputLabel-root": {
                      // color: "green", // Màu chữ của label
                    },
                    "& .MuiOutlinedInput-root": {
                      padding: "1px",
                      "& fieldset": {
                        // borderColor: "green",
                      },
                      "&:hover fieldset": {
                        borderColor: "green",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "green",
                        borderWidth: "1px",
                      },
                    },
                  }}
                  label="Chọn User"
                />
              )}
            />
          </Box>
        </Box>
        <Box
          sx={{
            display: role === "sales" ? "none" : "flex",
            flexDirection: "column",
            gap: 1,
            mt: 1,
            p: 1,
            width: "100%",
            border: "0.5px solid green",
            borderRadius: 1,
          }}
        >
          <TextField
            select
            size="small"
            label="Trạng thái cuộc gọi"
            value={params.disposition}
            onChange={(e) => {
              setParams({ ...params, disposition: e.target.value });
              setIsLoading(!isLoading);
            }}
            sx={{
              "& .MuiOutlinedInput-root": {
                padding: "1px",
                "& fieldset": {
                  // borderColor: "green",
                },
                "&:hover fieldset": {
                  borderColor: "green",
                },
                "&.Mui-focused fieldset": {
                  borderColor: "green",
                  borderWidth: "1px",
                },
              },
            }}
          >
            {
              statusList.map((item: any, index: any) => (
                <MenuItem key={index} value={item.value}>
                  {item.title}
                </MenuItem>
              ))}
          </TextField>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 1,
            mt: 1,
            width: "100%",
            border: "0.5px solid green",
            borderRadius: 1,
          }}
        >
          {DateRangePickerWithButtonField()}
          {/* <Typography className="title text-center text-neutral-400">
                Lọc theo ngày tháng
              </Typography> */}
          {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={["DateTimeRangePicker"]}>
              <DemoItem
                label="Lọc theo ngày tháng"
                component="DateTimeRangePicker"
              >
                <MobileDateTimeRangePicker
                  slotProps={{
                    textField: { size: "small" },
                    shortcuts: {
                      items: shortcutsItems,
                    },
                  }}
                  // defaultValue={[dayjs(), dayjs()]}
                  onChange={(newValue: any) => {
                    console.log("gte: ", newValue?.[0]?.$d.toString());
                    console.log("lte: ", newValue?.[1]?.$d.toString());
                    setParams({
                      ...params,
                      fromDate: newValue?.[0] || "",
                      toDate: newValue?.[1] || "",
                    });
                    setIsLoading(!isLoading);
                  }}
                />
              </DemoItem>
            </DemoContainer>
          </LocalizationProvider> */}
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 1,
            mt: 1,
            p: 1,
            width: "100%",
            border: "0.5px solid green",
            borderRadius: 1,
          }}
        >
          <TextField
            select
            size="small"
            label="Hành động"
            id=""
            value={action}
            className="p-1 rounded-sm"
            onChange={(e) => handleAction(e)}
            sx={{
              // padding: "1px",
              color: "green",
              "& .MuiInputBase-input": {
                // color: "green", // Text color
                // padding: "1px",
              },
              // '& .MuiInputBase-root': {
              //   padding: '1px', // Thiết lập padding là 10px
              // },
              "& label.Mui-focused": {
                // color: "green",
              },
              "& .MuiInputLabel-root": {
                // color: "green", // Màu chữ của label
              },
              "& .MuiOutlinedInput-root": {
                padding: "1px",
                "& fieldset": {
                  // borderColor: "green",
                },
                "&:hover fieldset": {
                  borderColor: "green",
                },
                "&.Mui-focused fieldset": {
                  borderColor: "green",
                  borderWidth: "1px",
                },
              },
            }}
          >
            {rule &&
              rule.map((item: any, index: any) => (
                <MenuItem key={index} value={item}>
                  {item === "importData"
                    ? "Import Data"
                    : item === "assignData"
                    ? "Assign Data"
                    : item === "revokeData"
                    ? "Thu hồi Data"
                    : item === "exportData"
                    ? "Export Data"
                    : item === "deleteImport"
                    ? "Xoá file Import"
                    : item === "deleteData"
                    ? "Xoá data Lead"
                    : item === "getLinkCard"
                    ? "Lấy link"
                    : item === "exportCDR"
                    ? "Export CDR"
                    : item === "autocall"
                    ? "Tạo Autocall"
                    : ""}
                </MenuItem>
              ))}
            <MenuItem
              sx={{
                display: ["root", "admin"].includes(role) ? "flex" : "none",
              }}
              value={"migrate"}
            >
              Đồng bộ CDR
            </MenuItem>
          </TextField>
        </Box>
      </Card>
    );
  };

  const PlayAudio = () => {
    const [audio, setAudio] = useState<any>("");
    const [name, setName] = useState<any>("");
    const [volume, setVolume] = useState(0.8);
    const [duration, setDuration] = useState(0);
    const [current, setCurrent] = useState<number>(0);
    const [playbackSpeed, setPlaybackSpeed] = useState<number>(1);
    const [anchorEl, setAnchorEl] = useState<any>(null);
    const [play, setPlay] = useState<boolean>(false);

    const [position, setPosition] = React.useState(0);
    const [paused, setPaused] = React.useState(true);
    function formatDuration(value: number) {
      const minute = Math.floor(value / 60);
      const secondLeft = value - minute * 60;
      return `${minute}:${secondLeft < 10 ? `0${secondLeft}` : secondLeft}`;
    }

    const playAudio = () => {
      if (audio) {
        audio.play();
        setPaused(false);
      }
    };

    const pauseAudio = () => {
      if (audio) {
        audio.pause();
        setPaused(true);
      }
    };

    useEffect(() => {
      if (record) {
        pauseAudio();
        setAudio(new Audio(record));
        setName(title);
        setPosition(0);
        setDuration(list[CDRIndex]?.billsec);
        setPlaybackSpeed(1);
      }
      console.log("audio: ", record);
    }, [record]);

    useEffect(() => {
      if (audio) {
        const updateTime = () => {
          setPosition(Math.floor(audio.currentTime));
          setDuration(Math.floor(audio.duration));
          if (Number(audio.currentTime) >= duration) setPaused(true);
        };
        audio.addEventListener("timeupdate", updateTime);
        // console.log("duration: ", audio.duration);
        return () => {
          audio.removeEventListener("timeupdate", updateTime);
        };
      }
    }, [audio]);

    const handleVolumeChange = (event) => {
      const newVolume = event.target.value;
      setVolume(newVolume);
      if (audio) {
        audio.volume = newVolume;
      }
    };

    const handleSeek = (v) => {
      const seekTime = v;
      audio.currentTime = seekTime;
    };

    const handlePlaybackSpeedChange = (speed) => {
      setPlaybackSpeed(speed);
      audio.playbackRate = speed;
      setAnchorEl(null);
    };

    const handleMenuOpen = (event) => {
      setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
      setAnchorEl(null);
    };

    // const downloadAudio = () => {
    //   const link = document.createElement("a");
    //   link.href = record;
    //   link.download = "audio.mp3";
    //   document.body.appendChild(link);
    //   link.click();
    // };
    const downloadAudio = () => {
      fetch(record)
        .then(response => response.blob())
        .then(blob => {
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement('a');
          const split = record.split('/')
          link.href = url;
          link.download = split[split.length -1];
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        })
        .catch(error => console.error('Error downloading audio:', error));
    };
    const iOSBoxShadow =
      "0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.13),0 0 0 1px rgba(0,0,0,0.02)";

    const IOSSlider = styled(Slider)(({ theme }) => ({
      color: theme.palette.mode === "dark" ? "#0a84ff" : "#007bff",
      height: 5,
      padding: "15px 0",
      "& .MuiSlider-thumb": {
        height: 20,
        width: 20,
        backgroundColor: "#fff",
        boxShadow: "0 0 2px 0px rgba(0, 0, 0, 0.1)",
        "&:focus, &:hover, &.Mui-active": {
          boxShadow: "0px 0px 3px 1px rgba(0, 0, 0, 0.1)",
          // Reset on touch devices, it doesn't add specificity
          "@media (hover: none)": {
            boxShadow: iOSBoxShadow,
          },
        },
        "&:before": {
          boxShadow:
            "0px 0px 1px 0px rgba(0,0,0,0.2), 0px 0px 0px 0px rgba(0,0,0,0.14), 0px 0px 1px 0px rgba(0,0,0,0.12)",
        },
      },
      "& .MuiSlider-valueLabel": {
        fontSize: 12,
        fontWeight: "normal",
        top: -6,
        backgroundColor: "unset",
        color: theme.palette.text.primary,
        "&::before": {
          display: "none",
        },
        "& *": {
          background: "transparent",
          color: theme.palette.mode === "dark" ? "#fff" : "#000",
        },
      },
      "& .MuiSlider-track": {
        border: "none",
        height: 5,
      },
      "& .MuiSlider-rail": {
        opacity: 0.5,
        boxShadow: "inset 0px 0px 4px -2px #000",
        backgroundColor: "#d0d0d0",
      },
    }));

    const WallPaper = styled("div")({
      position: "absolute",
      width: "100%",
      height: "100%",
      top: 0,
      left: 0,
      overflow: "hidden",
      background:
        "linear-gradient(rgb(255, 38, 142) 0%, rgb(255, 105, 79) 100%)",
      transition: "all 500ms cubic-bezier(0.175, 0.885, 0.32, 1.275) 0s",
      "&::before": {
        content: '""',
        width: "140%",
        height: "140%",
        position: "absolute",
        top: "-40%",
        right: "-50%",
        background:
          "radial-gradient(at center center, rgb(62, 79, 249) 0%, rgba(62, 79, 249, 0) 64%)",
      },
      "&::after": {
        content: '""',
        width: "140%",
        height: "140%",
        position: "absolute",
        bottom: "-50%",
        left: "-30%",
        background:
          "radial-gradient(at center center, rgb(247, 237, 225) 0%, rgba(247, 237, 225, 0) 70%)",
        transform: "rotate(30deg)",
      },
    });

    const Widget = styled("div")(({ theme }) => ({
      padding: 16,
      borderRadius: 16,
      width: 343,
      maxWidth: "100%",
      marginTop: 36,
      marginBottom: 36,
      marginLeft: 36,
      marginRight: 36,
      position: "relative",
      zIndex: 1,
      backgroundColor:
        theme.palette.mode === "dark"
          ? "rgba(0,0,0,0.6)"
          : "rgba(255,255,255,0.4)",
      backdropFilter: "blur(40px)",
      [theme.breakpoints.down("sm")]: {
        margin: "auto",
      },
    }));

    const CoverImage = styled("div")({
      width: 100,
      height: 100,
      objectFit: "cover",
      overflow: "hidden",
      flexShrink: 0,
      borderRadius: 8,
      backgroundColor: "rgba(0,0,0,0.08)",
      "& > img": {
        width: "100%",
      },
    });

    const TinyText = styled(Typography)({
      fontSize: "0.75rem",
      opacity: 0.38,
      fontWeight: 500,
      letterSpacing: 0.2,
    });
    const mainIconColor = theme.palette.mode === "dark" ? "#fff" : "#000";
    const lightIconColor =
      theme.palette.mode === "dark"
        ? "rgba(255,255,255,0.4)"
        : "rgba(0,0,0,0.4)";
    return (
      <Dialog
        open={isOpenPlay}
        onClose={() => setIsOpenPlay(false)}
        fullScreen={fullScreen}
      >
        <IconButton
          sx={{
            width: "fit-content",
            positon: "fixed",
            ml: "auto",
            right: "10px",
            top: "10px",
            zIndex: 1,
            [theme.breakpoints.up("sm")]: {
              display: "none",
            },
          }}
          onClick={() => setIsOpenPlay(false)}
        >
          <CloseIcon />
        </IconButton>
        <Widget>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <CoverImage>
              <img alt="logo" src={logo} />
            </CoverImage>
            <Box sx={{ ml: 1.5, minWidth: 0 }}>
              <Typography
                variant="caption"
                color="text.secondary"
                fontWeight={500}
              >
                {list[CDRIndex]?.cnum}
              </Typography>
              <Typography noWrap>
                <b>{list[CDRIndex]?.name}</b>
              </Typography>
              <Typography noWrap letterSpacing={-0.25}>
                Gọi đến số {list[CDRIndex]?.dst}
              </Typography>
            </Box>
          </Box>
          <Slider
            aria-label="time-indicator"
            size="small"
            value={position}
            min={0}
            step={1}
            max={duration}
            onChange={(_, value) => {
              // setPosition(value as number)
              handleSeek(value as number);
            }}
            sx={{
              color:
                theme.palette.mode === "dark" ? "#fff" : "rgba(0,0,0,0.87)",
              height: 4,
              "& .MuiSlider-thumb": {
                width: 8,
                height: 8,
                transition: "0.3s cubic-bezier(.47,1.64,.41,.8)",
                "&::before": {
                  boxShadow: "0 2px 12px 0 rgba(0,0,0,0.4)",
                },
                "&:hover, &.Mui-focusVisible": {
                  boxShadow: `0px 0px 0px 8px ${
                    theme.palette.mode === "dark"
                      ? "rgb(255 255 255 / 16%)"
                      : "rgb(0 0 0 / 16%)"
                  }`,
                },
                "&.Mui-active": {
                  width: 20,
                  height: 20,
                },
              },
              "& .MuiSlider-rail": {
                opacity: 0.28,
              },
            }}
          />
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              mt: -2,
            }}
          >
            <TinyText>{formatDuration(position)}</TinyText>
            <TinyText>{formatDuration(duration - position)}</TinyText>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              mt: -1,
            }}
          >
            <IconButton aria-label="previous song">
              <FastRewindRounded fontSize="large" htmlColor={mainIconColor} />
            </IconButton>
            <IconButton
              aria-label={paused ? "play" : "pause"}
              onClick={() => {
                setPaused(!paused);
                if (paused) {
                  playAudio();
                } else {
                  pauseAudio();
                }
              }}
            >
              {paused ? (
                <PlayArrowRounded
                  sx={{ fontSize: "3rem" }}
                  htmlColor={mainIconColor}
                />
              ) : (
                <PauseRounded
                  sx={{ fontSize: "3rem" }}
                  htmlColor={mainIconColor}
                />
              )}
            </IconButton>
            <IconButton aria-label="next song">
              <FastForwardRounded fontSize="large" htmlColor={mainIconColor} />
            </IconButton>
          </Box>
          <Stack
            spacing={2}
            direction="row"
            sx={{ mb: 1, px: 1 }}
            alignItems="center"
          >
            <VolumeDownRounded htmlColor={lightIconColor} />
            <Slider
              aria-label="Volume"
              min={0}
              max={1}
              step={0.01}
              value={volume}
              onChange={handleVolumeChange}
              sx={{
                color:
                  theme.palette.mode === "dark" ? "#fff" : "rgba(0,0,0,0.87)",
                "& .MuiSlider-track": {
                  border: "none",
                },
                "& .MuiSlider-thumb": {
                  width: 24,
                  height: 24,
                  backgroundColor: "#fff",
                  "&::before": {
                    boxShadow: "0 4px 8px rgba(0,0,0,0.4)",
                  },
                  "&:hover, &.Mui-focusVisible, &.Mui-active": {
                    boxShadow: "none",
                  },
                },
              }}
            />
            <VolumeUpRounded htmlColor={lightIconColor} />
            <Button onClick={downloadAudio} color="inherit">
              Download
            </Button>
            <Button
              onClick={handleMenuOpen}
              color="inherit"
              startIcon={<Speed />}
              aria-controls="speed-menu-"
              aria-haspopup="true"
              sx={{
                position: "relative",
                textTransform: "none",
              }}
            >
              {playbackSpeed}x
              <Card
                id="speed-menu"
                // anchorEl={anchorEl}
                // keepMounted
                sx={{
                  display: Boolean(anchorEl) ? "" : "none",
                  position: "absolute",
                }}
                // open={Boolean(anchorEl)}
                // transformOrigin={{
                //   vertical: 'top',
                //   horizontal: 'right',
                // }}
                // onClose={handleMenuClose}
              >
                <MenuItem
                  onClick={(e) => {
                    e.stopPropagation();
                    handlePlaybackSpeedChange(0.5);
                  }}
                >
                  0.5x
                </MenuItem>
                <MenuItem
                  onClick={(e) => {
                    handlePlaybackSpeedChange(1);
                    e.stopPropagation();
                  }}
                >
                  1x
                </MenuItem>
                <MenuItem
                  onClick={(e) => {
                    handlePlaybackSpeedChange(1.5);

                    e.stopPropagation();
                  }}
                >
                  1.5x
                </MenuItem>
                <MenuItem
                  onClick={(e) => {
                    handlePlaybackSpeedChange(2);
                    e.stopPropagation();
                  }}
                >
                  2x
                </MenuItem>
              </Card>
            </Button>
          </Stack>
        </Widget>
        <WallPaper />
      </Dialog>
    );
  };

  return (
    <>
      <Helmet>
        <title>CDRPage</title>
        <meta
          name="description"
          content="A Boilerplate application CustomersPage"
        />
      </Helmet>
      {/* <span>My CustomersPage</span> */}
      <div className="download-data dialog">
        <Dialog
          open={isOpenDownload}
          title="Export data"
          // submitTitle="Import"
          // closeTitle="Hủy"
          // onSubmit={submitImport}
          onClose={toggleDialogExport}
        >
          <div className="mx-auto w-[400px] flex flex-col gap-2">
            {/* <input type="file" className="mt-6" onChange={handleSelectFile} /> */}
            <label className="inline-flex items-center px-2 cursor-pointer">
              <input
                type="radio"
                className="form-radio"
                value="limit"
                checked={optionDownload === "limit"}
                onChange={(e) => {
                  setOptionDownload(e.target.value);
                }}
              />
              <span
                className={`ml-1 hover:text-neutral-400 ${
                  optionDownload === "limit" ? "text-green-500" : ""
                }`}
              >
                {`Xuất data theo limit ${limit} dòng đã chọn`}
              </span>
            </label>
            <label className="inline-flex items-center px-2 cursor-pointer">
              <input
                type="radio"
                className="form-radio"
                value="full"
                checked={optionDownload === "full"}
                onChange={(e) => {
                  setOptionDownload(e.target.value);
                }}
              />
              <span
                className={`ml-1 hover:text-neutral-400 ${
                  optionDownload === "full" ? "text-green-500" : ""
                }`}
              >
                Xuất full theo điều kiện đã lọc
              </span>
            </label>
            <button
              className="p-2 mb-4 w-[90%] mx-auto mt-5 bg-green-600 rounded-full font-bold"
              onClick={handleDownload}
            >
              Export
            </button>
          </div>
        </Dialog>
      </div>
      <div className="download-data dialog">
        <Dialog
          open={isOpenMigrate}
          title="Đồng bộ data"
          onClose={toggleDialogMigrate}
        >
          <div className="mx-auto w-[400px] flex flex-col gap-2">
            <div className="content flex flex-col m-5 gap-2">
              <p>Từ ngày</p>
              <input
                type="datetime-local"
                className="p-1 bg-neutral-800 rounded-sm"
                value={migrateFrom}
                onChange={(e) => {
                  setMigrateFrom(e.target.value);
                }}
              />
              <p>Đến ngày</p>
              <input
                type="datetime-local"
                className="p-1 bg-neutral-800 rounded-sm"
                value={migrateTo}
                onChange={(e) => {
                  setMigrateTo(e.target.value);
                }}
              />
            </div>
            <button
              className="p-2 mb-4 w-[90%] mx-auto mt-5 bg-green-600 rounded-full font-bold"
              onClick={handleMigrate}
            >
              Đồng bộ
            </button>
          </div>
        </Dialog>
      </div>
      <div className="loading-data">
        <Loading isOpen={isLoadingDialog}>
          <div className="mx-10 mt-10 w-[300px] flex flex-col gap-2"></div>
        </Loading>
      </div>
      {viewType === "vertical" ? (
        <Paper
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "start",
            alignItems: "start",
            p: 2,
            gap: 2,
            overflow: "auto",
            [theme.breakpoints.down("sm")]: {
              justifyContent: "center",
              gap: 5,
            },
          }}
        >
          <Box
            sx={{
              width: "100%",
              display: "flex",
              position: "fixed",
              borderBottom: 0.5,
              borderColor: "divider",
              top: 0,
              zIndex: 1,
              overflowX: "auto",
            }}
          >
            <Card
              sx={{
                width: "100%",
                height: "auto",
                minWidth: "fit-content",
                display: "flex",
                flexDirection: "row",
                justifyContent: "start",
                alignItems: "center",
                gap: 0.5,
                p: 1,
                // cursor: "pointer",
                overflow: "auto",
                transition: "all 0.5s",
                "&: hover": {
                  // transform: "scale(1.005)",
                },
                // background: "linear-gradient(to bottom, #66c142, #42c1bf)",
              }}
            >
              <Checkbox
                size="small"
                sx={{ color: "green" }}
                checked={isCheckedAll}
                onChange={(e) => {
                  setIsCheckedAll(!isCheckedAll);
                  handleCheckedAll();
                }}
                inputProps={{ "aria-label": "controlled" }}
              />
              <Typography
                sx={{
                  width: "200px",
                  height: "fit-content",
                  textTransform: "capitalize",
                  textAlign: "start",
                  fontWeight: "bold",
                }}
              >
                Tên
              </Typography>
              <Typography
                sx={{
                  width: "100px",
                  textTransform: "capitalize",
                  fontWeight: "bold",
                }}
              >
                Số nội bộ
              </Typography>
              <Typography
                sx={{
                  width: "100px",
                  textTransform: "capitalize",
                  fontWeight: "bold",
                }}
              >
                Số bị gọi
              </Typography>
              <Typography
                sx={{
                  width: "150px",
                  textTransform: "capitalize",
                  fontWeight: "bold",
                }}
              >
                Thời lượng
              </Typography>
              <Typography
                sx={{
                  width: "150px",
                  textTransform: "capitalize",
                  fontWeight: "bold",
                }}
              >
                Đàm thoại
              </Typography>
              <Typography
                sx={{
                  width: "150px",
                  textTransform: "capitalize",
                  fontWeight: "bold",
                }}
              >
                Trạng thái
              </Typography>
              <Typography
                sx={{
                  width: "150px",
                  textTransform: "capitalize",
                  fontWeight: "bold",
                }}
              >
                Gọi lúc
              </Typography>
            </Card>
          </Box>
          <Card
            sx={{
              width: "100%",
              height: "auto",
              minWidth: "fit-content",
              display: "flex",
              flexDirection: "row",
              justifyContent: "start",
              alignItems: "center",
              gap: 0.5,
              p: 1,
              cursor: "pointer",
              overflow: "hidden",
              position: "relative",
              transition: "all 0.5s",
              "&: hover": {},
            }}
          >
            <Checkbox
              size="small"
              sx={{ color: "green" }}
              checked={isCheckedAll}
              onChange={(e) => {
                setIsCheckedAll(!isCheckedAll);
                handleCheckedAll();
              }}
              inputProps={{ "aria-label": "controlled" }}
            />

            <Typography
              sx={{
                width: "200px",
                height: "fit-content",
                textTransform: "capitalize",
                textAlign: "start",
                fontWeight: "bold",
              }}
            >
              Tên
            </Typography>
            <Typography
              sx={{
                width: "100px",
                textTransform: "capitalize",
                fontWeight: "bold",
              }}
            >
              Số nội bộ
            </Typography>
            <Typography
              sx={{
                width: "100px",
                textTransform: "capitalize",
                fontWeight: "bold",
              }}
            >
              Số bị gọi
            </Typography>
            <Typography
              sx={{
                width: "150px",
                textTransform: "capitalize",
                fontWeight: "bold",
              }}
            >
              Thời lượng
            </Typography>
            <Typography
              sx={{
                width: "150px",
                textTransform: "capitalize",
                fontWeight: "bold",
              }}
            >
              Đàm thoại
            </Typography>
            <Typography
              sx={{
                width: "150px",
                textTransform: "capitalize",
                fontWeight: "bold",
              }}
            >
              Trạng thái
            </Typography>
            <Typography
              sx={{
                width: "150px",
                textTransform: "capitalize",
                fontWeight: "bold",
              }}
            >
              Gọi lúc
            </Typography>
          </Card>
          {list?.length &&
            list.map((item, index) => (
              <Card
                key={index}
                sx={{
                  width: "100%",
                  height: "auto",
                  minWidth: "fit-content",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "start",
                  alignItems: "center",
                  gap: 0.5,
                  p: 1,
                  cursor: item.dstID ? "pointer" : '',
                  overflow: "hidden",
                  position: "relative",
                  transition: "all 0.5s",
                  "&: hover": {},
                  // background: "linear-gradient(to bottom, #66c142, #42c1bf)",
                }}
                onClick={() => {if(item.dstID)navigate(`/customers/${item.dstID}`)}}
                // onClick={() => {}}
              >
                <Checkbox
                  size="small"
                  sx={{
                    width: "fit-content",
                    color: "green",
                  }}
                  checked={item.checked}
                  onClick={(e: any) => {
                    e.stopPropagation();
                    handleChecked(item._id);
                  }}
                  inputProps={{ "aria-label": "controlled" }}
                />
                <Typography
                  sx={{
                    width: "200px",
                    height: "fit-content",
                    textTransform: "capitalize",
                    textAlign: "start",
                    color: "orangered",
                    fontWeight: "bold",
                  }}
                >
                  {item.name}
                </Typography>
                <Typography
                  sx={{
                    width: "100px",
                    textTransform: "capitalize",
                  }}
                >
                  {item.cnum}
                </Typography>
                <Typography
                  sx={{
                    width: "100px",
                    textTransform: "capitalize",
                  }}
                >
                  {convertString(item.dst)}
                </Typography>
                <Typography
                  sx={{
                    width: "150px",
                    textTransform: "capitalize",
                  }}
                >
                  {time(item.duration)}
                </Typography>
                <Typography
                  sx={{
                    width: "150px",
                    display: "flex",
                    alignItems: "center",
                    gap: 1,
                    cursor: "pointer",
                    textTransform: "capitalize",
                    color: item.billsec > 0 ? "rgb(255, 38, 45)" : "",
                  }}
                  onClick={(e: any) => {
                    e.stopPropagation();
                    if (item.disposition === "ANSWERED") {
                      setRecord(item.linkRecord);
                      setTitle(item.dst);
                      setIsOpenPlay(true);
                      setCDRIndex(index);
                    }
                  }}
                >
                  {time(item.billsec)}
                  <PlayCircleOutlineIcon
                    sx={{
                      display: item.disposition === "ANSWERED" ? "" : "none",
                    }}
                  />
                </Typography>
                <Typography
                  sx={{
                    width: "150px",
                    textTransform: "capitalize",
                  }}
                >
                  {item.disposition === "ANSWERED"
                    ? "Đã nghe máy"
                    : item.disposition === "NO ANSWER"
                    ? "Không nghe máy"
                    : item.disposition === "BUSY"
                    ? "Máy bận"
                    : "Gọi thất bại"}
                </Typography>
                <Typography
                  sx={{
                    width: "150px",
                  }}
                >
                  {formatTime(item.createdAt)}
                </Typography>
              </Card>
            ))}
          {more()}
        </Paper>
      ) : (
        <Paper
          sx={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "start",
            alignItems: "center",
            p: 2,
            gap: 2,
            [theme.breakpoints.down("sm")]: {
              justifyContent: "center",
              gap: 5,
            },
          }}
        >
          {list?.map((item: any, index) => (
            <Card
              key={index}
              sx={{
                width: "150px",
                height: "200px",
                display: "flex",
                flexDirection: "column",
                gap: 0.5,
                p: 1,
                // cursor: "pointer",
                overflow: "hidden",
                position: "relative",
                transition: "all 0.9s",
                "&: hover": {
                  transform: "scale(1.05)",
                },
                // background: "linear-gradient(to bottom, #66c142, #42c1bf)",
              }}
              // onClick={() => navigate(`/customers/${item._id}`)}
              onClick={() => {
                setCustomerID(item._id);
              }}
            >
              <Typography
                variant="h6"
                sx={{
                  textTransform: "capitalize",
                  textAlign: "center",
                  color: "orangered",
                  fontWeight: "bold",
                  mx: "auto",
                }}
              >
                {item.name}
              </Typography>
              <Typography
                sx={{
                  display: "flex",
                  alignItems: "end",
                  gap: 0.5,
                  textTransform: "capitalize",
                  mx: "auto",
                  fontWeight: "bold",
                }}
              >
                <CallIcon fontSize="small" />
                {item.cnum}
              </Typography>
              <Typography
                sx={{
                  display: "flex",
                  alignItems: "end",
                  gap: 0.5,
                  textTransform: "capitalize",
                  mx: "auto",
                }}
              >
                <ContactPhoneIcon />
                {item.dst}
              </Typography>
              <Typography
                sx={{
                  textTransform: "capitalize",
                  mx: "auto",
                  color: item.disposition === "ANSWERED" ? "#34b37e" : "",
                }}
              >
                {item.disposition === "ANSWERED"
                  ? "Đã nghe máy"
                  : item.disposition === "NO ANSWER"
                  ? "Không nghe máy"
                  : item.disposition === "BUSY"
                  ? "Máy bận"
                  : "Gọi thất bại"}
              </Typography>
              <Typography
                sx={{
                  textTransform: "capitalize",
                  mx: "auto",
                }}
              >
                {`Thời lượng: ${time(item.duration)}`}
              </Typography>
              <Typography
                sx={{
                  textTransform: "capitalize",
                  mx: "auto",
                  color: item.billsec > 0 ? "rgb(255, 38, 45)" : "",
                }}
                onClick={(e: any) => {
                  e.stopPropagation();
                  if (item.disposition === "ANSWERED") {
                    setRecord(item.linkRecord);
                    setTitle(item.dst);
                    setIsOpenPlay(true);
                    setCDRIndex(index);
                  }
                }}
              >
                <PlayCircleOutlineIcon />
                {time(item.billsec)}
              </Typography>
              <Checkbox
                sx={{
                  width: "fit-content",
                  mx: "auto",
                  color: "green",
                  left: 0,
                  right: 0,
                  bottom: 0,
                  position: "absolute",
                }}
                checked={item.checked}
                onClick={(e: any) => {
                  e.stopPropagation();
                  handleChecked(item._id);
                }}
                inputProps={{ "aria-label": "controlled" }}
              />
            </Card>
          ))}
          {more()}
          <SwipeableDrawer
            anchor={"right"}
            open={openDrawer}
            onClose={() => setOpenDrawer(false)}
            onOpen={() => setOpenDrawer(true)}
            // hideBackdrop
            variant="persistent"
            PaperProps={{
              sx: {
                bgcolor: "transparent",
                p: { md: 3, sm: 1 },
                boxShadow: "none",
              },
            }}
          >
            {filterMenu()}
          </SwipeableDrawer>
        </Paper>
      )}
      {PlayAudio()}
      <SwipeableDrawer
        anchor={"right"}
        open={openDrawer}
        onClose={() => setOpenDrawer(false)}
        onOpen={() => setOpenDrawer(true)}
        // variant="persistent"
        PaperProps={{
          sx: {
            bgcolor: "transparent",
            p: { md: 3, sm: 1 },
            boxShadow: "none",
          },
        }}
      >
        {filterMenu()}
      </SwipeableDrawer>
    </>
  );
}
