import axios from "axios";
import { getItem, saveItem, removeItem } from "store/LocalStorage";
import { AUTH_URL } from "./config";
import { toast } from "react-toastify";

const toastOptions: any = {
  position: "bottom-left",
  theme: "light",
};
// @Refresh token
export const refreshToken = async (refreshToken: string) => {
  // const refreshToken = localStorage.getItem('REFRESH_TOKEN');
  let success = false;
  await axios
    .request({
      method: "post",
      maxBodyLength: Infinity,
      url: `${AUTH_URL}/token`,
      headers: {
        "Content-Type": "application/json",
      },
      data: { refreshToken },
    })
    .then(async (res) => {
      const data = res.data.data;
      localStorage.setItem("TOKEN", data.accessToken);
      localStorage.setItem("REFRESH_TOKEN", data.refreshToken);
      localStorage.setItem("FIRSTNAME", data.firstName);
      localStorage.setItem("LASTNAME", data.lastName);
      localStorage.setItem("PHONE", data.phone);
      localStorage.setItem("USER_ID", data._id);
      localStorage.setItem("ROLE", data.role);
      success = true;
    })
    .catch((error) => {
      if (window.location.pathname !== "/") window.location.pathname = "/";
      // saveItem({ isOpenLogin: true });
      toast.error(error.response.data.message, toastOptions);
      removeItem("REFRESH_TOKEN");
      success = false;
    });
  return success;
};
