import React, { useEffect, useState } from 'react';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import styled from 'styled-components';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import MuiTextField from '@mui/material/TextField';
import OutlinedInput from '@mui/material/OutlinedInput';
import MuiFormControl from '@mui/material/FormControl';
import { LOADING_STATE } from 'app/constants/app';
import {
  IconButton,
  InputAdornment,
  InputLabel,
  Slide,
  Typography,
} from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { useDispatch, useSelector } from 'react-redux';
import { userInfoSelector } from 'app/slice/user/selectors';
import { useUtilsSlice } from 'app/slice/utils';
import { DIALOG_TYPE } from 'app/slice/utils/types';
import { request } from 'utils/request';
import omitBy from 'lodash/omitBy';
import { MAIL_REGEX, PASSWORD_REGEX, PHONE_REGEX } from 'utils/regexp';
import { toast } from 'react-toastify';

const TextField = styled(MuiTextField)({
  '& .MuiTextField-root': {
    color: '#fff',
  },
  '& .MuiInputLabel-root': {
    color: '#fff',
  },
  '& .MuiInputBase-root': {
    color: '#fff',
    borderRadius: 10,
  },
  '& label.Mui-focused': {
    color: 'gold',
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: 'gold',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#59f81a',
    },
    '&:hover fieldset': {
      borderColor: '#B2BAC2',
    },
    '&.Mui-focused fieldset': {
      borderColor: 'gold',
    },
  },
});

const FormControl = styled(MuiFormControl)({
  '& .MuiTextField-root': {
    color: '#FFFFFF',
  },
  '& .MuiInputLabel-root': {
    color: '#fff',
  },
  '& .MuiInputBase-root': {
    color: '#FFFFFF',
    borderRadius: 10,
  },
  '& label.Mui-focused': {
    color: 'gold',
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: 'gold',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#59f81a',
    },
    '&:hover fieldset': {
      borderColor: '#B2BAC2',
    },
    '&.Mui-focused fieldset': {
      borderColor: 'gold',
    },
  },
});

const enum STEP {
  SIGN_UP = 'SIGN_UP',
  SIGN_IN = 'SIGN_IN',
  ONBOARDING = 'ONBOARDING',
}

export default function SignUpDialog({
  onSignUp,
  onSignIn,
  theme,
  setLoading,
  dialogType,
  dialogCloseAction,
}) {
  const dispatch = useDispatch();
  // const userInfo = useSelector(userInfoSelector);

  // const { actions: userActions } = useUserSlice();
  const { actions: utilsAction } = useUtilsSlice();

  const [showPassword, setShowPassword] = useState(false);
  const [userData, setUserData] = useState({
    email: '',
    phone: '',
    password: '',
    lastName: '',
    firstName: '',
    code: '',
  });

  const [onboardingData, setOnboardingData] = useState<{
    sessionId: string | null;
    code: string | null;
  }>({
    sessionId: null,
    code: null,
  });

  const [emailInvalid, setEmailInvalid] = useState<string>('');
  const [phoneInvalid, setPhoneInvalid] = useState<string>('');
  const [passwordInvalid, setPasswordInvalid] = useState<string>('');

  const [step, setStep] = useState<STEP>(STEP.SIGN_UP);

  const containerRef = React.useRef<HTMLElement>(null);
  // const userLoadingStatus = userInfo?.loading;

  // useEffect(() => {
  //   sessionStorage.clear();
  //   if (userLoadingStatus) {
  //     userLoadingStatus === LOADING_STATE.LOADED && dialogCloseAction();
  //   }
  // }, [userLoadingStatus]);

  const handleSetShowPassword = () => {
    setShowPassword(true);
  };

  const handleHiddenPassword = () => {
    setShowPassword(false);
  };

  const handleSignUp = async () => {
    let error = false;

    if (isEmpty(get(userData, 'password', ''))) {
      setPasswordInvalid('Password không được để trống');
      toast.warning('Bạn chưa điền mật khẩu')
      error = true;
    }

    if (
      error ||
      !isEmpty(phoneInvalid) ||
      !isEmpty(passwordInvalid)
    ) {
      return;
    }

    setLoading(true);

    try {
      const result: any = await request(
        'post',
        `${process.env.REACT_APP_BE_AUTH}/user/register`,
        omitBy(
          {
            firstName: get(userData, 'firstName'),
            lastName: get(userData, 'lastName'),
            phone: get(userData, 'phone'),
            password: get(userData, 'password'),
            code: get(userData, 'code'),
          },
          isEmpty,
        ),
      );

      // const { method, sessionId, to } = result;
      // if (sessionId) {
      //   setOnboardingData({
      //     ...onboardingData,
      //     sessionId,
      //   });

      //   setStep(STEP.ONBOARDING);
      // }
      dispatch(utilsAction.setDialogApp({ dialogType: DIALOG_TYPE.SIGN_IN }));
      toast.success('Bạn đã đăng ký thành công, vui lòng đăng nhập để sử dụng dịch vụ')
    } catch (error) {
      return dispatch(
        utilsAction.showSnackbar({
          message: get(error, 'message', 'Error Undefined'),
          variant: 'error',
        }),
      );
    } finally {
      setLoading(false);
    }
  };

  const handleOnboading = async () => {
    if (!onboardingData.code || !onboardingData.sessionId) {
      return dispatch(
        utilsAction.showSnackbar({
          message: 'Please input verification code',
          variant: 'error',
        }),
      );
    }

    setLoading(true);

    try {
      const user: any = await request(
        'post',
        `${process.env.REACT_APP_BACKEND_URL}/api/v1/users/onboard`,
        onboardingData,
      );

      dispatch(utilsAction.setDialogApp({ dialogType: DIALOG_TYPE.SIGN_IN }));
      dispatch(
        utilsAction.showSnackbar({
          message: 'User created successfully, Please login',
          variant: 'success',
        }),
      );
    } catch (error) {
      return dispatch(
        utilsAction.showSnackbar({
          message: get(error, 'message', 'Error Undefined'),
          variant: 'error',
        }),
      );
    } finally {
      setLoading(false);
    }
  };

  const signUpSlide = () => (
    <Slide
      direction="right"
      in={step === STEP.SIGN_UP}
      container={containerRef.current}
    >
      <Box
        className="scrollbar-small"
        sx={{
          display: step === STEP.SIGN_UP ? 'flex' : 'none',
          flexDirection: 'column',
          gap: 3,
          py: 1,
          height: '100%',
          overflowY: 'auto'
        }}
      >
        {/* <TextField
          error={!isEmpty(emailInvalid)}
          helperText={emailInvalid}
          label="Email"
          variant="outlined"
          value={userData.email}
          onChange={event => {
            const value = event.target.value.toLowerCase();
            setUserData({
              ...userData,
              email: value,
            });

            if (!isEmpty(value) && !MAIL_REGEX.test(value)) {
              setEmailInvalid('Email address format incorrect');
            } else {
              setEmailInvalid('');
            }
          }}
          sx={{
            width: '100%',
            borderRadius: 8,
          }}
        /> */}
        <TextField
          // error={!isEmpty(phoneInvalid)}
          // helperText={phoneInvalid}
          value={userData.lastName}
          label="Họ"
          variant="outlined"
          onChange={event => {
            const value = event.target.value;
            setUserData({
              ...userData,
              lastName: value,
            });
          }}
          sx={{
            width: '100%',
            borderRadius: 8,
          }}
        />
        <TextField
          // error={!isEmpty(phoneInvalid)}
          // helperText={phoneInvalid}
          value={userData.firstName}
          label="Tên"
          variant="outlined"
          onChange={event => {
            const value = event.target.value;
            setUserData({
              ...userData,
              firstName: value,
            });
          }}
          sx={{
            width: '100%',
            borderRadius: 8,
          }}
        />
        <TextField
          error={!isEmpty(phoneInvalid)}
          helperText={phoneInvalid}
          value={userData.phone}
          label="Phone"
          variant="outlined"
          onChange={event => {
            const value = event.target.value.toLowerCase();
            setUserData({
              ...userData,
              phone: value,
            });

            if (!isEmpty(value) && !PHONE_REGEX.test(value)) {
              setPhoneInvalid('Định dạng số điện thoại không đúng');
            } else {
              setPhoneInvalid('');
            }
          }}
          sx={{
            width: '100%',
            borderRadius: 8,
          }}
        />
        <TextField
          // error={!isEmpty(phoneInvalid)}
          helperText={phoneInvalid}
          value={userData.code}
          label="Mã giới thiệu"
          variant="outlined"
          onChange={event => {
            const value = event.target.value.toLowerCase();
            setUserData({
              ...userData,
              code: value,
            });
          }}
          sx={{
            width: '100%',
            borderRadius: 8,
          }}
        />
        <Typography
          sx={{
            display: !isEmpty(passwordInvalid) ? 'block' : 'none',
            color: 'red',
            fontStyle: 'italic',
          }}
        >
          {passwordInvalid}
        </Typography>
        <FormControl
          sx={{
            width: '100%',
            borderRadius: 8,
          }}
          variant="outlined"
        >
          <InputLabel htmlFor="outlined-adornment-password">
            Mật khẩu
          </InputLabel>
          <OutlinedInput
            error={!isEmpty(passwordInvalid)}
            id="outlined-adornment-password"
            type={showPassword ? 'text' : 'password'}
            value={userData.password}
            onChange={event => {
              const value = event.target.value;
              setUserData({
                ...userData,
                password: event.target.value,
              });

              if (!isEmpty(value) && PASSWORD_REGEX.test(value)) {
                setPasswordInvalid(
                  'Mật khẩu gồm 8 ký tự, chứa ít nhất 1 chữ viết hoa, 1 chữ viết thường và 1 ký tự đặc biệt',
                );
              } else {
                setPasswordInvalid('');
              }
            }}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  sx={{
                    color: '#474747',
                  }}
                  aria-label="toggle password visibility"
                  onClick={
                    showPassword ? handleHiddenPassword : handleSetShowPassword
                  }
                  onMouseDown={() => {}}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
            label="Password"
          />
        </FormControl>
      </Box>
    </Slide>
  );

  const onBoardingSlide = () => (
    <Slide
      direction="left"
      in={step === STEP.ONBOARDING}
      container={containerRef.current}
    >
      <Box
        sx={{
          display: step === STEP.ONBOARDING ? 'block' : 'none',
          width: '100%',
        }}
      >
        <Typography sx={{ color: '#d1d1d184' }}>
          {`Please check email inbox and input the Verification Code`}
        </Typography>
        <TextField
          error={!onboardingData.code}
          helperText={!onboardingData.code ? 'Incorrect, code is 6 digit' : ''}
          label="Verification Code"
          variant="outlined"
          onChange={event => {
            if (/^[0-9]{6,6}$/g.test(event.target.value)) {
              setOnboardingData({
                ...onboardingData,
                code: event.target.value,
              });
            }
          }}
          sx={{
            marginTop: '28px',
            width: '100%',
          }}
        />
      </Box>
    </Slide>
  );

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        marginTop: '30px',
        marginBottom: '30px',
        marginLeft: '60px',
        marginRight: '60px',
        height: '100%',
        // position: 'relative',
        [theme.breakpoints.down('sm')]: {
          marginTop: '10px',
          height: 'calc(100% - 40px)',
        },
      }}
    >
      <Box
        sx={{
          backgroundColor: '#0f0f0f',
          display: 'inline-flex',
          borderRadius: 2,
          mb: 2,
        }}
      >
        <Button
          sx={{
            paddingTop: '8px',
            paddingBottom: '8px',
            paddingLeft: '18px',
            paddingRight: '18px',

            marginTop: '8px',
            marginBottom: '8px',
            marginLeft: '12px',
            marginRight: '6px',

            color: 'white',
            backgroundColor: '#26262b',
            ...(dialogType === DIALOG_TYPE.SIGN_UP && {
              backgroundColor: '#26262b',
              fontWeight: 700,
            }),
            borderRadius: 2,
            textTransform: 'capitalize',
          }}
          variant="text"
          onClick={() => {
            setStep(STEP.SIGN_UP);
            onSignUp();
          }}
        >
          ĐĂNG KÝ
        </Button>
        <Button
          sx={{
            paddingTop: '8px',
            paddingBottom: '8px',
            paddingLeft: '18px',
            paddingRight: '18px',

            marginTop: '8px',
            marginBottom: '8px',
            marginLeft: '6px',
            marginRight: '12px',

            color: 'white',
            ...(dialogType === DIALOG_TYPE.SIGN_IN && {
              backgroundColor: '#26262b',
              fontWeight: 700,
            }),
            fontSize: 12,
            borderRadius: 2,
            textTransform: 'capitalize',
          }}
          variant="text"
          onClick={onSignIn}
        >
          ĐĂNG NHẬP
        </Button>
      </Box>

      <Box
        className="scrollbar-small"
        ref={containerRef}
        sx={{
          width: '100%',
          maxHeight: '60%',
          height: 'auto',
          overflowX: 'hidden',
          overflowY: 'auto',
          // mb: 1,
        }}
      >
        {step === STEP.SIGN_UP ? signUpSlide() : onBoardingSlide()}
      </Box>

      <Box
        sx={{
          mt: 1,
          // mb: 10,
          width: '100%',
          bottom: 1,
        }}
      >
        <Button
          sx={{
            // mt: 'auto',
            padding: '8px',
            width: '100%',
            color: 'black',
            borderRadius: 8,
            backgroundColor: '#fdcf39',
            '&:hover': {
              backgroundColor: '#c4a337',
              color: 'black',
            },
            fontSize: 12,
            fontWeight: 700,
            textTransform: 'capitalize',
          }}
          variant="text"
          onClick={() => {
            switch (step) {
              case STEP.SIGN_UP:
                return handleSignUp();

              case STEP.ONBOARDING:
                return handleOnboading();

              default:
                break;
            }
          }}
        >
          ĐĂNG KÝ
        </Button>
      </Box>
    </Box>
  );
}
