import * as React from "react";
import { toast } from "react-toastify";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import AccountCircle from "@mui/icons-material/AccountCircle";
import IconButton from "@mui/material/IconButton";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import Badge from "@mui/material/Badge";
import NotificationsIcon from "@mui/icons-material/Notifications";
import AddAlertIcon from "@mui/icons-material/AddAlert";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import SaveIcon from "@mui/icons-material/Save";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import SendIcon from "@mui/icons-material/Send";

import Autocomplete from "@mui/material/Autocomplete";
import Dialog from "@mui/material/Dialog";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Card from "@mui/material/Card";
import Tooltip from "@mui/material/Tooltip";
import Checkbox from "@mui/material/Checkbox";
import { useMediaQuery, useTheme } from "@mui/material";

import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { userInfoSelector } from "app/slice/user/selectors";
import { useUtilsSlice } from "app/slice/utils";
import { appSelector } from "app/slice/utils/selectors";
import { useUserSlice } from "app/slice/user";
import { DIALOG_TYPE } from "app/slice/utils/types";

import axios from "axios";
import { API_URL, AUTH_URL } from "utils/config";
import { socket } from "utils/socket";
import JoditEditor from "jodit-react";
import { editorConfigJodit, createMarkup } from "utils/jodit";

import ringtoneSrc from "assets/sound/call-to-attention-123107.mp3";

export default function MenuListAlert() {
  const [list, setList] = React.useState<any[]>([]);
  const [listUser, setListUser] = React.useState<any[]>([]);
  const [reference, setReference] = React.useState<any[]>([]);
  const [notify, setNotify] = React.useState<any>({});
  const [ip, setIp] = React.useState<string>("");
  const [data, setData] = React.useState<string>("");
  const [name, setName] = React.useState<string>("");
  const [total, setTotal] = React.useState<number>(0);
  const [totalViewed, setTotalViewed] = React.useState<number>(0);
  const [openDialog, setOpenDialog] = React.useState<boolean>(false);
  const [openNotifyDialog, setOpenNotifyDialog] =
    React.useState<boolean>(false);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { actions: utilsAction } = useUtilsSlice();
  const { actions: userAction } = useUserSlice();
  const userInfo = useSelector(userInfoSelector);
  const { dialogType } = useSelector(appSelector);
  const handleClose = () => {
    setAnchorEl(null);
  };
  const onSignIn = () => {
    dispatch(utilsAction.setDialogApp({ dialogType: DIALOG_TYPE.SIGN_IN }));
  };
  const onSignOut = () => {
    dispatch(userAction.signOut({}));
  };
  const redirectMyAccount = () => {
    navigate("/account");
  };
  const theme = useTheme();

  const fetchData = () => {
    const token = localStorage.getItem("TOKEN");
    const configGetCustomer = {
      method: "get",
      url: `${API_URL}/notify?reverse=1`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    axios
      .request(configGetCustomer)
      .then((res) => {
        const result = res?.data?.data;
        // console.log("result: ", result);
        if (result) {
          setList(result.notifies);
          setTotal(result.count);
          setTotalViewed(result.totalViewed);
          result.notifies.map((item: any) => {
            const checkExist = item.reference.find(
              (el) => el._id === userInfo?.userId
            );
            // console.log('checkExist: ', checkExist, item.name)
            if (
              checkExist &&
              item?.type === "alert" &&
              !item.viewer.includes(userInfo?.userId)
            ) {
              handleClickOpen(item);
            }
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  React.useEffect(() => {
    const fetchIp = async () => {
      await fetch("https://api.ipify.org?format=json")
        .then((response) => response.json())
        .then((data) => setIp(data.ip))
        .catch((error) => console.log(error));
    };
    fetchIp();
  }, []);

  React.useEffect(() => {
    socket.emit("newIP", ip);
  }, [ip]);

  React.useEffect(() => {
    const token = localStorage.getItem("TOKEN");
    if (token) fetchData();
    socket.on("new notify", (data) => {
      if(token) {
        const ringToneAlert = new Audio(ringtoneSrc);
        ringToneAlert.play();
        fetchData();
      }
      // console.log("data-socket: ", data);
    });
  }, [isLoading]);

  React.useEffect(() => {
    const token = localStorage.getItem("TOKEN");
    const fetchDataUser = () => {
      axios
        .get(`${AUTH_URL}/list?limit=infinity`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          // console.log('data: ', res.data.data)
          const result = res.data.data;
          if (result) {
            const cloneUsers = [...result.users];
            const newUsers = cloneUsers.map((item) => {
              return {
                _id: item._id,
                firstName: item.firstName,
                lastName: item.lastName,
                code: item.code,
              };
            });
            setListUser(newUsers);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    };
    if (token) fetchDataUser();
  }, []);

  const handleClickOpen = (item) => {
    setNotify(item);
    setData(item.content);
    setOpenDialog(true);
  };

  const handleUpdateViewed = () => {
    const newViewer = [...notify?.viewer];
    const checkViewed = newViewer.includes(userInfo?.userId);
    if (!checkViewed) {
      newViewer.push(userInfo?.userId);
      const token = localStorage.getItem("TOKEN");
      const _id: any = notify._id;
      const configPutStatus = {
        method: "put",
        url: `${API_URL}/notify`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        data: JSON.stringify({ _id, viewer: newViewer }),
      };
      axios
        .request(configPutStatus)
        .then((res) => {
          // toast.success(res.data.message);
          fetchData();
        })
        .catch((error) => {
          console.log(error);
          // toast.error(error.response.data.message);
        });
    }
  };

  const handleCreateNotify = () => {
    const token = localStorage.getItem("TOKEN");
    if (!notify.type) {
      toast.error("Vui lòng chọn kiểu thông báo");
    } else {
      const newReference = reference.map((item: any) => item._id);
      const configPost = {
        method: "post",
        url: `${API_URL}/notify`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        data: JSON.stringify({ ...notify, name, reference: newReference }),
      };
      axios
        .request(configPost)
        .then((res) => {
          toast.success(res.data.message);
          fetchData();
        })
        .catch((error) => {
          console.log(error);
          toast.error(error.response.data.message);
        });
    }
  };

  const AlertDialog = () => {
    const handleClose = () => {
      setOpenDialog(false);
      handleUpdateViewed();
    };

    return (
      <React.Fragment>
        <Dialog
          open={openDialog}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <Box
            sx={{
              minWidth: "200px",
              minHeight: "100px",
              p: 2,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: 1,
              border: 1,
              borderColor: "orangered",
              bgcolor: (theme) =>
                theme.palette.mode === "dark" ? "black" : "",
            }}
          >
            <Typography
              sx={{
                fontWeight: "bold",
                whiteSpace: "wrap",
                textTransform: "capitalize",
              }}
            >
              {notify?.name}
            </Typography>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                [theme.breakpoints.down("sm")]: {},
                width: "100%",
                height: "100%",
                overflowY: "hidden",
              }}
              dangerouslySetInnerHTML={createMarkup(data)}
            ></Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                mt: 3,
                color: "divider",
                fontStyle: "italic",
                // gap: 1
              }}
            >
              <Typography>Gửi từ:</Typography>
              <Typography
                sx={{
                  fontStyle: "italic",
                }}
              >{`${notify?.user?.title || 'Hệ thống'} - ${notify?.user?.name || 'Tự động'}`}</Typography>
            </Box>
          </Box>
        </Dialog>
      </React.Fragment>
    );
  };

  function ReferenceTags() {
    const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
    const checkedIcon = <CheckBoxIcon fontSize="small" />;
    return (
      <Autocomplete
        size="small"
        fullWidth
        multiple
        id="checkboxes-tags-status-customer"
        options={listUser}
        disableCloseOnSelect
        value={reference}
        getOptionLabel={(option) => option.code}
        // isOptionEqualToValue={(option, value) => option === value}
        onChange={(event: any, newValue: any | null) => {
          setNotify({ ...notify, reference: newValue });
          setReference(newValue);
        }}
        renderOption={(props, option, { selected }) => (
          <li {...props}>
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              style={{ marginRight: 0 }}
              checked={selected}
            />
            {`${option.lastName} ${option.firstName} - ${option.code}`}
          </li>
        )}
        // style={{ width: 200 }}
        renderInput={(param) => (
          <TextField
            {...param}
            label="Người nhận"
            placeholder="Người nhận"
            sx={{
              "& .MuiOutlinedInput-root": {
                padding: "1px",
                "& fieldset": {
                  // borderColor: "green",
                },
                "&:hover fieldset": {
                  borderColor: "green",
                },
                "&.Mui-focused fieldset": {
                  borderColor: "green",
                  borderWidth: "1px",
                },
              },
            }}
          />
        )}
      />
    );
  }

  const AddNotifyDialog = () => {
    return (
      <Dialog
        disableEnforceFocus
        fullScreen={!useMediaQuery(theme.breakpoints.up("md"))}
        fullWidth={true}
        maxWidth="md"
        open={openNotifyDialog}
        onClose={() => setOpenNotifyDialog(false)}
        PaperProps={{
          sx: {
            borderRadius: 2,
            backgroundColor: "#efeff1",
            margin: 0,
            height: "90vh",
            [theme.breakpoints.down("sm")]: {
              height: "100vh",
            },
            overflow: "hidden",
          },
        }}
      >
        <Box
          sx={{
            p: 5,
            overflow: "hidden",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignContent: "center",
              mb: 1,
            }}
          >
            <Typography
              sx={{
                color: "orangered",
                fontWeight: "bold",
              }}
            >
              Soạn văn bản thông báo
            </Typography>
            <Box>
              <Button
                color="success"
                variant="outlined"
                onClick={handleCreateNotify}
              >
                <SendIcon />
                Gửi
              </Button>
              <IconButton
                color="warning"
                onClick={() => setOpenNotifyDialog(false)}
                sx={{
                  position: "absolute",
                  top: 2,
                  right: 2,
                }}
              >
                <HighlightOffIcon />
              </IconButton>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 1,
            }}
          >
            <Typography variant="h6">Kiểu thông báo</Typography>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 0.5,
              }}
            >
              <Checkbox
                sx={{ color: "green" }}
                checked={notify.type === "alert"}
                onChange={() => {
                  setNotify({ ...notify, type: "alert" });
                }}
                inputProps={{ "aria-label": "controlled" }}
              />
              <Typography>Khẩn cấp</Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 0.5,
              }}
            >
              <Checkbox
                sx={{ color: "green" }}
                checked={notify.type === "notify"}
                onChange={() => {
                  setNotify({ ...notify, type: "notify" });
                }}
                inputProps={{ "aria-label": "controlled" }}
              />
              <Typography>Thông báo thường</Typography>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              gap: 2,
              pb: 5,
              pt: 1,
              flexDirection: "column",
              [theme.breakpoints.down("sm")]: {},
              width: "100%",
              height: "100%",
              overflowY: "auto",
            }}
          >
            {ReferenceTags()}
            <TextField
              size="small"
              label="Tiêu đề"
              value={name}
              onChange={(e) => {
                setName(e.target.value);
              }}
            />
            <JoditEditor
              value={data}
              config={editorConfigJodit}
              onChange={(value) => {
                setData(value);
                setNotify({ ...notify, content: value });
              }}
            />
          </Box>
        </Box>
      </Dialog>
    );
  };

  if (userInfo?.userId) {
    return (
      <div>
        <IconButton
          size="large"
          color="inherit"
          aria-describedby={id}
          onClick={handleClick}
        >
          <Badge badgeContent={total - totalViewed} color="error">
            <NotificationsIcon />
          </Badge>
        </IconButton>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          sx={{
            "& .MuiPopover-paper": {
              bgcolor: (theme) =>
                theme.palette.mode === "dark" ? "black" : "",
            },
          }}
        >
          <Tooltip title="Thêm thông báo mới">
            <IconButton
              sx={{ m: 0.5 }}
              onClick={() => setOpenNotifyDialog(true)}
            >
              <AddAlertIcon fontSize="small" color="primary" />
            </IconButton>
          </Tooltip>
          <Typography
            sx={{
              display: total - totalViewed > 0 ? "" : "none",
              p: 1,
              color: "orangered",
              fontStyle: "italic",
            }}
          >{`Bạn có ${total - totalViewed} thông báo chưa đọc`}</Typography>
          <Box
            sx={{
              p: 0,
              display: "flex",
              flexDirection: "column",
              maxWidth: "500px",
              minWidth: "200px",
              maxHeight: "200px",
              borderTop: 1,
              borderColor: "divider",
            }}
          >
            {list?.map((item, index) => (
              <Box
                key={index}
                sx={{
                  p: 1,
                  cursor: "pointer",
                  background: item.viewer.includes(userInfo?.userId)
                    ? ""
                    : "linear-gradient(to bottom, #66c142, #42c1bf)",
                  borderTop: 0.5,
                  borderColor: "divider",
                }}
                onClick={() => {
                  handleClickOpen(item);
                }}
              >
                <Typography>{item.name}</Typography>
              </Box>
            ))}
          </Box>
        </Popover>
        {AlertDialog()}
        {AddNotifyDialog()}
      </div>
    );
  }
}
