import { OrderProduct } from '../cart/types';

export enum LOCAL_STORAGE {
  TOKEN = 'TOKEN',
  REFRESH_TOKEN = 'REFRESH_TOKEN',
  EMAIL = 'EMAIL',
  EMAIL_VERIFY = 'EMAIL_VERIFY',
  PHONE = 'PHONE',
  PHONE_VERIFY = 'PHONE_VERIFY',
  ACTIVE = 'ACTIVE',
  USER_ID = 'USER_ID',
  ROLE = 'ROLE',
  FIRST_NAME = 'FIRSTNAME',
  LAST_NAME = 'LASTNAME',

}

export const getToken = (): string | null =>
  window.localStorage && window.localStorage.getItem(LOCAL_STORAGE.TOKEN);

export const saveToken = (token: string) => {
  window.localStorage &&
    window.localStorage.setItem(LOCAL_STORAGE.TOKEN, token);
};
export const saveRefreshToken = (token: string) => {
  window.localStorage &&
    window.localStorage.setItem(LOCAL_STORAGE.REFRESH_TOKEN, token);
};

export const getEmail = (): string | null =>
  window.localStorage && window.localStorage.getItem(LOCAL_STORAGE.EMAIL);

export const saveEmail = (email: string) => {
  window.localStorage &&
    window.localStorage.setItem(LOCAL_STORAGE.EMAIL, email);
};

export const getPhone = (): string | null =>
  window.localStorage && window.localStorage.getItem(LOCAL_STORAGE.PHONE);

export const savePhone = (phone: string) => {
  window.localStorage &&
    window.localStorage.setItem(LOCAL_STORAGE.PHONE, phone);
};

export const getRole = (): string | null =>
  window.localStorage && window.localStorage.getItem(LOCAL_STORAGE.ROLE);

export const saveRole = (role: string) => {
  window.localStorage && window.localStorage.setItem(LOCAL_STORAGE.ROLE, role);
};

export const saveUserId = (userid: string) => {
  window.localStorage &&
    window.localStorage.setItem(LOCAL_STORAGE.USER_ID, userid);
};

export const getUserId = (): string | null =>
  window.localStorage && window.localStorage.getItem(LOCAL_STORAGE.USER_ID);

export const saveFirstName = (firstName: string) => {
  window.localStorage &&
    window.localStorage.setItem(LOCAL_STORAGE.FIRST_NAME, firstName);
};

export const getFirstName = (): string | null =>
  window.localStorage && window.localStorage.getItem(LOCAL_STORAGE.FIRST_NAME);

export const saveLastName = (lastName: string) => {
  window.localStorage &&
    window.localStorage.setItem(LOCAL_STORAGE.LAST_NAME, lastName);
};

export const getLastName = (): string | null =>
  window.localStorage && window.localStorage.getItem(LOCAL_STORAGE.LAST_NAME);

export const saveEmailVerify = (isVerify: boolean) => {
  window.localStorage &&
    window.localStorage.setItem(LOCAL_STORAGE.EMAIL_VERIFY, String(isVerify));
};

export const getEmailVerify = (): boolean =>
  window.localStorage &&
  window.localStorage.getItem(LOCAL_STORAGE.EMAIL_VERIFY) === 'true';

export const savePhoneVerify = (isVerify: boolean) => {
  window.localStorage &&
    window.localStorage.setItem(LOCAL_STORAGE.PHONE_VERIFY, String(isVerify));
};

export const getPhoneVerify = (): boolean =>
  window.localStorage &&
  window.localStorage.getItem(LOCAL_STORAGE.PHONE_VERIFY) === 'true';

export const saveActive = (saveActive: boolean) => {
  window.localStorage &&
    window.localStorage.setItem(LOCAL_STORAGE.ACTIVE, String(saveActive));
};

export const getActive = (): boolean =>
  window.localStorage &&
  window.localStorage.getItem(LOCAL_STORAGE.ACTIVE) === 'true';

// Cart
export enum CART_LOCAL_STORAGE {
  CARTS = 'CARTS',
}

export const saveCarts = (carts: OrderProduct[]) => {
  window.localStorage &&
    window.localStorage.setItem(
      CART_LOCAL_STORAGE.CARTS,
      JSON.stringify(carts),
    );
};

export const getOrderProducts = (): OrderProduct[] => {
  try {
    return (
      window.localStorage &&
      JSON.parse(window.localStorage.getItem(CART_LOCAL_STORAGE.CARTS) || '[]')
    );
  } catch (error) {
    return [];
  }
};
