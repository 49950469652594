import { refreshToken } from 'utils/refreshToken';
import { PayloadAction } from "@reduxjs/toolkit";
import { ActionError } from "types";
import { createSlice } from "utils/@reduxjs/toolkit"; // Importing from `utils` makes them more type-safe ✅
import { useInjectReducer, useInjectSaga } from "utils/redux-injectors";
import {
  LOCAL_STORAGE,
  getEmail,
  getEmailVerify,
  getPhone,
  getPhoneVerify,
  getRole,
  getToken,
  getUserId,
  getFirstName,
  getLastName,
  saveEmail,
  saveEmailVerify,
  savePhone,
  savePhoneVerify,
  saveRole,
  saveToken,
  saveRefreshToken,
  saveUserId,
  saveFirstName,
  saveLastName,
} from "../utils/localStorage";
import { LOADING_STATE } from "../utils/types";
import userSaga from "./saga";
import { Signup, SignIn, UserState } from "./types";
import { clearLocalStorageExceptKeys, clearLocalStorage } from 'store/LocalStorage';
// The initial state of the user
export const initialState: UserState = {
  token: getToken(),
  error: "",
  loading: LOADING_STATE.EMPTY,
  email: getEmail(),
  userId: getUserId(),
  phone: getPhone(),
  username: getEmail() || getPhone(),
  emailVerification: getEmailVerify(),
  phoneVerification: getPhoneVerify(),
  role: getRole(),
  firstName: getFirstName(),
  lastName: getLastName(),
};

const slice = createSlice({
  name: "user" as never,
  initialState,
  reducers: {
    clearLoading(state) {
      state = {
        ...initialState,
        loading: LOADING_STATE.LOADED,
      };
    },
    signUp(state, action: PayloadAction<Signup>) {
      // Here we say lets change the username in my user state when changeUsername actions fires
      // Type-safe: It will expect `string` when firing the action. ✅
      state = {
        ...initialState,
        loading: LOADING_STATE.LOADING,
      };
    },
    signUpSucceeded(state, action: PayloadAction<any>) {
      state.loading = LOADING_STATE.LOADED;
      state.email = action.payload.sendToMail;
    },
    signUpError(state, action: PayloadAction<ActionError>) {
      // Here we say lets change the username in my user state when changeUsername actions fires
      // Type-safe: It will expect `string` when firing the action. ✅
      state.loading = LOADING_STATE.LOADED_ERROR;
      state.error = action.payload;
    },
    signIn(state, action: PayloadAction<SignIn>) {
      // Here we say lets change the username in my user state when changeUsername actions fires
      // Type-safe: It will expect `string` when firing the action. ✅
      state.username = action.payload.username;
      state.loading = LOADING_STATE.LOADING;
    },
    signInError(state, action: PayloadAction<ActionError>) {
      state.loading = LOADING_STATE.LOADED_ERROR;
      state.error = action.payload;
    },
    signInSuccessed(state, action: PayloadAction<any>) {
      const {
        _id,
        token,
        refreshToken,
        email,
        // emailVerification,
        phone,
        // phoneVerification,
        role,
        firstName,
        lastName,
      } = action.payload;
      const keysToKeep = ['newTheme']
      clearLocalStorageExceptKeys(keysToKeep)
      // clearLocalStorage()
      state.loading = LOADING_STATE.LOADED;
      state.token = token;
      saveToken(token);
      saveRefreshToken(refreshToken);
      state.userId = _id;
      saveUserId(_id);
      state.email = email;
      saveEmail(email);
      // state.emailVerification = emailVerification;
      // saveEmailVerify(emailVerification);
      state.phone = phone;
      savePhone(phone);
      state.role = role;
      saveRole(role);
      state.firstName = firstName;
      saveFirstName(firstName)
      state.lastName = lastName;
      saveLastName(lastName)
      window.location.reload()
      // state.phoneVerification = phoneVerification;
      // savePhoneVerify(phoneVerification);
    },
    getMySeft(state, action: PayloadAction<any>) {
      const { token } = action.payload;
      state.token = token;
    },
    getMySeftSuccessed(state, action: PayloadAction<any>) {
      // const {
      //   _id,
      //   email,
      //   emailVerification,
      //   phone,
      //   phoneVerification,
      //   active,
      //   wallet,
      // } = action.payload;
      // const { balance } = wallet;
      // state.userId = _id;
      // saveUserId(_id);
      // state.email = email;
      // saveEmail(email);
      // state.emailVerification = emailVerification;
      // saveEmailVerify(emailVerification);
      // state.phone = phone;
      // savePhone(phone);
      // state.phoneVerification = phoneVerification;
      // savePhoneVerify(phoneVerification);
    },
    signOutWithoutReload(state, action: PayloadAction<any>) {
      Object.values(LOCAL_STORAGE).forEach((key) => {
        localStorage.removeItem(key);
      });
      state = initialState;
    },
    signOut(state, action: PayloadAction<any>) {
      state.loading = LOADING_STATE.LOADING;
    },
    signOutSuccess(state) {
      Object.values(LOCAL_STORAGE).forEach((key) => {
        localStorage.removeItem(key);
      });
      state = initialState;
      if (window.location.pathname !== "/") window.location.pathname = "/";
      window.location.reload();
    },
  },
});

/**
 * `actions` will be used to trigger change in the state from where ever you want
 */
export const { actions: userActions } = slice;

export const useUserSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer as any });
  useInjectSaga({ key: slice.name, saga: userSaga });
  return { actions: slice.actions };
};
