"use client";

import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  ReactNode,
} from "react";
import axios from "axios";
import { API_URL, AUTH_URL } from "utils/config";
import { toast } from "react-toastify";
import { toastOptions } from "utils/toast";
import { getItem, saveItem, clearLocalStorage } from "store/LocalStorage";
// import { useRouter } from "next/navigation";
import { createTheme, useTheme, ThemeProvider, keyframes } from "@mui/material";

import { pickTime, backupTime, pathTime, formatTime, time } from "utils/moment";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Unstable_Grid2";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import MenuItem from "@mui/material/MenuItem";
import Stack from "@mui/material/Stack";
import Chip from "@mui/material/Chip";
import Paper, { PaperProps } from "@mui/material/Paper";
import Tooltip from "@mui/material/Tooltip";
import Zoom from "@mui/material/Zoom";

import KeyboardDoubleArrowDownIcon from "@mui/icons-material/KeyboardDoubleArrowDown";
import DoubleArrowIcon from "@mui/icons-material/DoubleArrow";
import CallEndIcon from "@mui/icons-material/CallEnd";
import CallIcon from "@mui/icons-material/Call";

import { baselightTheme } from "utils/theme/DefaultColors";

import JsSIP from "jssip";

import Draggable from "react-draggable";
// import PhoneApp from "utils/PhoneApp/PhoneApp";

interface Props {
  children: ReactNode;
}

// Định nghĩa kiểu dữ liệu cho người dùng
interface User {
  username: string;
  firstname: string;
  lastname: string;
  email: string;
  phone: string;
  refreshToken: string;
  token: string;
}
interface DataLogin {
  username: string;
  password: string;
}

// Định nghĩa kiểu dữ liệu cho AuthContext
interface AuthContextType {
  user: User | null;
  login: (username: string, password: string) => void;
  logout: () => void;
  makeCall: (dst: string, name: string) => void;
  endCall: () => void;
  handleOpenPhone: () => void;
  handleDisableMic: () => void;
  handleMuteAudio: () => void;
  handleHold: () => void;
  callState: string;
  isOpenPhone: boolean;
  isDisableMic: boolean;
  isMuteAudio: boolean;
  isHold: boolean;
}

// Tạo AuthContext
const AuthContext = createContext<AuthContextType | undefined>(undefined);

// Tạo AuthProvider
export const AuthProvider: React.FC<Props> = ({ children }) => {
  const [user, setUser] = useState<User | null>(null);
  const [dataLogin, setDataLogin] = useState<DataLogin | null>(null);

  const [incommingId, setIncommingId] = useState<string>("");
  const [coolPhone, setCoolphone] = useState<any>(null);
  const [session, setSession] = useState<any>(null);
  const [extension, setExtension] = useState<any>("9999");
  const [displayName, setDisplayName] = useState<any>("Test RTC");
  const [password, setPassword] = useState<string>("Thien123#$");
  const [sipServer, setSipServer] = useState<string>("");
  const [wsHost, setWsHost] = useState<string>("ws://103.27.238.195:8088/ws");
  const [wsPort, setWsPort] = useState<string>("");
  const [openCalling, setOpenCalling] = React.useState(false);
  const [openOutGoing, setOpenOutGoing] = React.useState(false);
  const [openInComming, setOpenInComming] = React.useState(false);
  const [callDuration, setCallDuration] = React.useState<string>("");
  const [callState, setCallState] = React.useState<string>("");
  const [dstName, setDstName] = React.useState<string>("hangup");
  const [isOpenPhone, setIsOpenPhone] = React.useState<boolean>(false);
  const [isDisableMic, setIsDisableMic] = React.useState<boolean>(false);
  const [isMuteAudio, setIsMuteAudio] = React.useState<boolean>(false);
  const [isHold, setIsHold] = React.useState<boolean>(false);
  // const router = useRouter();
  const theme = useTheme();
  let intervalId: any;
  const token = localStorage.getItem("TOKEN");
  useEffect(() => {
    const fetchSIP = async () => {
      // console.log("user: ", user);
      const config = {
        method: "get",
        url: `${AUTH_URL}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };
      try {
        await axios.request(config).then((res) => {
          const data = res.data.data;
          if (data) {
            // console.log("data: ", data);
            setExtension(data.sipAccount?.extension);
            setPassword(data.sipAccount?.password);
            setSipServer(data.sipAccount?.server);
            setWsHost(data.sipSocket);
            setDisplayName(data.email);
          }
          // toast.success(res?.data.message, toastOptions);
          // console.log("data: ", res.data);
        });
      } catch (error: any) {
        // if (error?.response?.status === 403) router.push("/login");
        console.error("Error fetching sip:", error);
        // toast.error(error.response?.data.message, toastOptions);
      }
    };

    if (token) fetchSIP();
  }, [dataLogin]);

  useEffect(() => {
    if (isDisableMic) {
      disableMicrophone(session);
    } else {
      enableMicrophone(session);
    }
  }, [isDisableMic]);

  useEffect(() => {
    if (isMuteAudio) {
      muteAudio(session);
    } else {
      unmuteAudio(session);
    }
  }, [isMuteAudio]);

  useEffect(() => {
    if (isHold) {
      hold();
    } else {
      unhold();
    }
  }, [isHold]);

  // Count time in calling
  function countTime(startTime: any) {
    // Lấy thời điểm hiện tại
    const now = new Date();

    // Tính số giây đã trôi qua
    const elapsedSeconds = Math.floor(
      (now.getTime() - startTime.getTime()) / 1000
    );

    // Tính số phút và giây
    const minutes = Math.floor(elapsedSeconds / 60);
    const seconds = elapsedSeconds % 60;

    // Trả về thời gian dưới dạng chuỗi
    return `${minutes.toString().padStart(2, "0")}:${seconds
      .toString()
      .padStart(2, "0")}`;
  }

  // Assumes you have a media element on the DOM

  function disableMicrophone(session: any) {
    if (session && session.sessionDescriptionHandler) {
      const pc = session.sessionDescriptionHandler.peerConnection;
      if (pc) {
        pc.getSenders().forEach((sender: any) => {
          if (sender.track && sender.track.kind === "audio") {
            sender.track.enabled = false;
          }
        });
      }
    }
    session?.mute();
  }

  function enableMicrophone(session: any) {
    if (session && session.sessionDescriptionHandler) {
      const pc = session.sessionDescriptionHandler.peerConnection;
      if (pc) {
        pc.getSenders().forEach((sender: any) => {
          if (sender.track && sender.track.kind === "audio") {
            sender.track.enabled = true;
          }
        });
      }
    }
    session?.unmute();
  }

  function muteAudio(session: any) {
    if (session && session.sessionDescriptionHandler) {
      const pc = session.sessionDescriptionHandler.peerConnection;
      if (pc) {
        pc.getReceivers().forEach((receiver: any) => {
          if (receiver.track && receiver.track.kind === "audio") {
            receiver.track.enabled = false;
          }
        });
      }
    }
  }

  function unmuteAudio(session: any) {
    if (session && session.sessionDescriptionHandler) {
      const pc = session.sessionDescriptionHandler.peerConnection;
      if (pc) {
        pc.getReceivers().forEach((receiver: any) => {
          if (receiver.track && receiver.track.kind === "audio") {
            receiver.track.enabled = true;
          }
        });
      }
    }
  }

  function hold() {
    session?.hold();
  }
  function unhold() {
    session?.unhold();
  }

  function resetCall() {
    if (intervalId) clearInterval(intervalId);
    setCallDuration("");
    setOpenOutGoing(false);
    setOpenInComming(false);
  }

  const socket = new JsSIP.WebSocketInterface(wsHost);
  // socket.via_transport = "ws";
  var configuration = {
    sockets: [socket],
    uri: `sip:${extension}@${sipServer}`,
    password: password,
    register_server: `sip:${sipServer}`,
    authorization_user: extension,
    // contact_uri: '123.21.233.69',
    use_preloaded_route: true,
    display_name: displayName,
    // hack_ip_in_contact: true,
    no_answer_timeout: 45,
  };
  let sessionjs: any;
  var remoteAudio = new window.Audio();
  remoteAudio.autoplay = true;
  useEffect(() => {
    if (sipServer) {
      const newCoolPhone = new JsSIP.UA(configuration);
      newCoolPhone.start();
      // newCoolPhone.register();
      setCoolphone(newCoolPhone);
    }
  }, [sipServer]);

  useEffect(() => {
    if (callState === "hangup") {
      toast.success("Cuộc gọi đã kết thúc", toastOptions);
    }
  }, [callState]);

  coolPhone?.on("newRTCSession", function (ev) {
    var newSession = ev.session;

    if (sessionjs) {
      // hangup any existing call
      sessionjs.terminate();
    }
    sessionjs = newSession;
    var completeSession = function () {
      if (intervalId) {
        clearInterval(intervalId);
        intervalId = null;
      }
      sessionjs = null;
      resetCall();
      setCallState("hangup");
    };

    if (sessionjs.direction === "outgoing") {
      // console.log("stream outgoing  -------->");
      sessionjs.on("connecting", function () {
        // console.log("CONNECT");
        setOpenOutGoing(true);
        setSession(sessionjs);
        setCallState("ringing");
      });
      sessionjs.on("peerconnection", function (e) {
        // console.log("1accepted");
      });
      sessionjs.on("ended", completeSession);
      sessionjs.on("failed", completeSession);
      sessionjs.on("accepted", function (e) {
        // console.log("accepted");
        setCallState("answered");
        const startTime = new Date(); // Thời điểm bắt đầu
        intervalId = setInterval(() => {
          const currentTime = countTime(startTime);
          setCallDuration(currentTime); // Hiển thị thời gian tính được
        }, 1000);
      });
      sessionjs.on("confirmed", function (e) {
        // console.log("CONFIRM STREAM");
      });
    }

    if (sessionjs.direction === "incoming") {
      // console.log("stream incoming  -------->");
      sessionjs.on("connecting", function (e) {
        // console.log("CONNECT: ", e);
      });
      sessionjs.on("peerconnection", function (e) {
        // console.log("1accepted: ", e);
        add_stream();
      });
      sessionjs.on("ended", completeSession);
      sessionjs.on("failed", completeSession);
      sessionjs.on("accepted", function (e) {
        console.log("accepted");
        setCallState("answered");
        const startTime = new Date(); // Thời điểm bắt đầu
        intervalId = setInterval(() => {
          const currentTime = countTime(startTime);
          setCallDuration(currentTime); // Hiển thị thời gian tính được
        }, 1000);
      });
      sessionjs.on("confirmed", function (e) {
        // console.log("CONFIRM STREAM");
      });

      console.log("Incoming Call: ", sessionjs);
      setSession(sessionjs);
      setOpenInComming(true);
      setCallState("ringing");
      setIncommingId(sessionjs._request.from._uri._user);
    }
  });
  const makeCall = async (numTels) => {
    const options = {
      mediaConstraints: { audio: true, video: false },
      pcConfig: {
        iceServers: [
          // { urls: "stun:stun.l.google.com:19302" },
          // {urls: "stun:stun.l.google.com:5349"},
          // { urls: "stun:stun1.l.google.com:19302" },
          // { urls: "stun:stun2.l.google.com:19302" },
          // { urls: "stun:stun3.l.google.com:19302" },
          // { urls: "stun:stun4.l.google.com:19302" },
          // { urls: "stun:stun.stunprotocol.org:3478" },
          // { urls: "stun:stun.services.mozilla.com" },
          // { urls: "stun:stun.sipgate.net" },
          // { urls: "stun:stun.ideasip.com" },
          // { urls: "stun:stun.ekiga.net" },
          // { urls: "stun:stun.fwdnet.net" },
        ],
        iceTransportPolicy: "all",
        bundlePolicy: "balanced",
        rtcpMuxPolicy: "require",
        iceCandidatePoolSize: 2,
        sdpSemantics: "unified-plan",
      },
    };

    const numTel = numTels.toString();

    try {
      // Kiểm tra coolPhone đã sẵn sàng
      if (!coolPhone) {
        toast.error(
          "Xin lỗi, điện thoại chưa được khởi tạo, vui lòng reload hoặc đăng nhập lại để sử dụng"
        );
        throw new Error("coolPhone is not initialized");
      }

      // Gọi hàm call và chờ nó hoàn thành
      await coolPhone.call(numTel, options);

      // Gọi hàm add_stream sau khi cuộc gọi đã bắt đầu
      add_stream();
    } catch (error) {
      console.error("Failed to make call:", error);
      toast.error("Khởi tạo cuộc gọi thất bại, vui lòng thử lại sau");
    }
  };

  function add_stream() {
    if (sessionjs && sessionjs.connection) {
      const handleAddStream = (e) => {
        remoteAudio.srcObject = e.stream;
      };

      sessionjs.connection.addEventListener("addstream", handleAddStream);

      // Cleanup function to remove the event listener when the session ends
      return () => {
        sessionjs.connection.removeEventListener("addstream", handleAddStream);
        remoteAudio.srcObject = null;
      };
    }
  }

  function endCall() {
    session.terminate();
  }

  const scaleUp1 = keyframes`
  0% {
    transform: scale(0.6);
    opacity: 0.5;
  }
  100% {
    transform: scale(1.3);
    opacity: 0;
  }
`;
  const scaleUp2 = keyframes`
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.1);
  }
`;
  const upUp = keyframes`
  0% {
    transform: translate(0px, -10px);
  }
  100% {
    transform: translate(0px, 10px);
  }
`;
  const leftLeft = keyframes`
  0% {
    transform: translate(-10px, 0px);
  }
  /* 50% {
    transform: translate(20px, -0px);
  } */
  100% {
    transform: translate(10px, 0px);
  }
`;

  // const makeCall = (dst: string, name?: string) => {
  //   setDstName(name || "");
  //   const target: any = UserAgent.makeURI(`sip:${dst}@${sipServer}`);
  //   const ringtone = new Audio(ringingSrc);
  //   // let intervalId: any;
  //   userAgent
  //     .start()
  //     .then(() => {
  //       const handleEnded = () => {
  //         ringtone.play();
  //       };
  //       ringtone.addEventListener("ended", handleEnded);
  //       const inviter = new Inviter(userAgent, target);
  //       inviter.stateChange.addListener((state: SessionState) => {
  //         console.log(`Session state changed to ${state}`);
  //         switch (state) {
  //           case SessionState.Initial:
  //             break;
  //           case SessionState.Establishing:
  //             setCallState("ringing");
  //             setOpenCalling(true);
  //             ringtone.play();
  //             setupRemoteMedia(inviter);
  //             break;
  //           case SessionState.Established:
  //             setupRemoteMedia(inviter);
  //             setCallState("answered");
  //             ringtone.pause();
  //             ringtone.removeEventListener("ended", handleEnded);
  //             const startTime = new Date(); // Thời điểm bắt đầu
  //             intervalId = setInterval(() => {
  //               const currentTime = countTime(startTime);
  //               setCallDuration(currentTime); // Hiển thị thời gian tính được
  //             }, 1000);
  //             break;
  //           case SessionState.Terminating:
  //           // fall through
  //           case SessionState.Terminated:
  //             if(intervalId) clearInterval(intervalId);
  //             setCallDuration("");
  //             setCallState("hangup");
  //             setOpenCalling(false);
  //             ringtone.pause();
  //             ringtone.removeEventListener("ended", handleEnded);
  //             // if (callState === "hangup")
  //             // console.log('Session state changed to ...')
  //               toast.success("Cuộc gọi đã kết thúc", toastOptions);
  //             cleanupMedia();
  //             break;
  //           default:
  //             throw new Error("Unknown session state.");
  //         }
  //       });
  //       inviter.invite().catch((error) => {
  //         console.error("Error inviting:", error);
  //         cleanupMedia();
  //       });
  //       setSession(inviter);
  //     })
  //     .catch((error) => {
  //       console.error("Error starting user agent:", error);
  //     });
  // };
  const acceptCall = () => {
    var options = {
      mediaConstraints: { audio: true, video: true },
      pcConfig: {
        rtcpMuxPolicy: "require",
        iceServers: [],
      },
    };
    session?.answer(options);
  };

  const handleOpenPhone = () => {
    setIsOpenPhone(!isOpenPhone);
    return !isOpenPhone;
  };

  const handleDisableMic = () => {
    setIsDisableMic(!isDisableMic);
    return !isDisableMic;
  };

  const handleMuteAudio = () => {
    setIsMuteAudio(!isMuteAudio);
    return !isMuteAudio;
  };
  const handleHold = () => {
    setIsHold(!isHold);
    return !isHold;
  };

  const PaperComponent = (props: PaperProps) => {
    return (
      <Draggable
        handle="#draggable-dialog-title"
        cancel={'[class*="MuiDialogContent-root"]'}
      >
        <Paper {...props} />
      </Draggable>
    );
  };

  const Calling = () => {
    return (
      <>
        <Box
          sx={{
            width: "fit-content",
            display: openInComming ? "flex" : "none",
            flexDirection: "column",
            alignItems: "center",
            gap: 1,
            p: 1,
            backgroundColor: theme.palette.secondary.main,
            position: "fixed",
            borderRadius: 3,
            top: 3,
            left: "50%",
            transform: "translateX(-50%)",
            zIndex: 9999,
          }}
        >
          <Typography>Cuộc gọi từ: {incommingId}</Typography>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 1,
            }}
          >
            <Box
              sx={{
                display: !callDuration ? "flex" : "none",
                justifyContent: "center",
                alignItems: "center",
                position: "relative",
              }}
            >
              <Box
                sx={{
                  width: "40px",
                  height: "40px",
                  position: "absolute",
                  bgcolor: "green",
                  borderRadius: "100%",
                  animation: `${scaleUp1} 2s ease-in-out infinite`,
                }}
              ></Box>
              <Tooltip
                title="Nghe máy"
                placement="top"
                TransitionComponent={Zoom}
              >
                <IconButton
                  sx={{
                    width: "fit-content",
                    color: "green",
                    animation: `${scaleUp2} 1s ease-in-out infinite alternate`,
                  }}
                  onClick={acceptCall}
                >
                  <CallIcon />
                </IconButton>
              </Tooltip>
            </Box>
            <Typography>{callDuration}</Typography>
            <Tooltip title="Gác máy" placement="top" TransitionComponent={Zoom}>
              <IconButton
                onClick={endCall}
                sx={{
                  width: "fit-content",
                  display: "flex",
                  color: "red",
                }}
              >
                <CallEndIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          </Box>
        </Box>
        <Box
          sx={{
            width: "fit-content",
            display: openOutGoing ? "flex" : "none",
            alignItems: "center",
            gap: 1,
            p: 1,
            backgroundColor: "secondary.main",
            position: "fixed",
            borderRadius: 3,
            top: 3,
            left: "50%",
            transform: "translateX(-50%)",
            zIndex: 99999,
          }}
        >
          <DoubleArrowIcon
            sx={{
              display: callDuration ? "none" : "",
              mx: "auto",
              animation: `${leftLeft} 1s ease-in-out infinite alternate`,
            }}
          />
          <Typography>{callDuration}</Typography>
          <Tooltip title="Gác máy" placement="top" TransitionComponent={Zoom}>
            <IconButton
              onClick={endCall}
              sx={{
                width: "fit-content",
                display: "flex",
                color: "red",
              }}
            >
              <CallEndIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        </Box>
      </>
    );
  };

  const login = (username: string, password: string) => {
    setDataLogin({ username, password });
  };

  const logout = () => {
    setUser(null);
    clearLocalStorage();
    setIsOpenPhone(false);
  };

  return (
    <AuthContext.Provider
      value={{
        user,
        login,
        logout,
        makeCall,
        endCall,
        handleHold,
        handleOpenPhone,
        handleDisableMic,
        handleMuteAudio,
        isOpenPhone,
        isDisableMic,
        isMuteAudio,
        isHold,
        callState,
      }}
    >
      <video id="mediaElement" style={{ width: "0px", height: "0px" }}></video>
      <video id="my-video" style={{ width: "0px", height: "0px" }}></video>
      <video id="peer-video" style={{ width: "0px", height: "0px" }}></video>
      <video id="selfView" style={{ width: "0px", height: "0px" }}></video>
      <video id="remoteView" style={{ width: "0px", height: "0px" }}></video>
      <Box sx={{ display: isOpenPhone ? "" : "none" }}>
        {/* <PhoneApp></PhoneApp> */}
      </Box>
      {Calling()}
      {children}
    </AuthContext.Provider>
  );
};

// Tạo một hook để sử dụng trong các components
export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};
