import axios from 'axios';
import { get } from 'lodash';

/**
 * Make a http request to a url
 * @param {string} m method
 * @param {string} url url
 * @param {string} token token for auth
 * @param {object} params
 */
export const request = (
  method: string,
  url: string,
  params?: any,
  token?: string,
  headerObj?: any,
  options?: any,
) => {
  let uri = url;
  const headers = {
    'Content-Type': 'application/json',
    Connection: 'keep-alive',
    ...headerObj,
  };

  if (token) {
    headers.Authorization = `Bearer ${token}`;
  }

  const _method = method.toUpperCase();
  const opts = {
    method: _method,
    headers,
    ...options,
  };

  if (_method === 'GET') {
    const query = new URLSearchParams(params).toString();
    uri += `?${query}`;
  } else {
    opts.data = JSON.stringify(params);
  }

  return new Promise((resolve, reject) => {
    axios(uri, { timeout: 300000, ...opts })
      .then(res => {
        const { data } = res || {};
        resolve(data);
      })
      .catch(err => {
        reject(get(err, 'response.data', err));
      });
  });
};
