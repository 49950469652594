import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'types';
import { DIALOG_TYPE, Snackbar } from './types';

export const utilsSelector = (state: RootState) => state.utils;

// Here type of `someState` will be inferred ✅
const snackbarSelector = createSelector(
  utilsSelector,
  (utils): Snackbar =>
    utils?.snackbar || {
      message: '',
      variant: 'default',
    },
);

const appSelector = createSelector(
  utilsSelector,
  utils =>
    utils?.app || {
      dialogType: DIALOG_TYPE.CLOSE,
    },
);

export { snackbarSelector, appSelector };
