import React, {useEffect} from "react";

export default function useBrowserNotification (){
    useEffect(() => {
      if (!('Notification' in window)) {
        console.error('This browser does not support desktop notifications');
      } else if (Notification.permission !== 'denied') {
        Notification.requestPermission()
      }
      // console.log('push notification')
    }, []);
  
    const showNotification = (title: string, options?: NotificationOptions, url?: string) => {
      if (!('Notification' in window)) {
        console.error('This browser does not support desktop notifications');
        return;
      }
  
      if (Notification.permission === 'granted') {
        const notification = new Notification(title, options);
        if(url) {
          notification.onclick = () =>{
            window.open(url, '_blank')
          }
        }
      } else if (Notification.permission !== 'denied') {
        Notification.requestPermission().then(permission => {
          if (permission === 'granted') {
            new Notification(title, options);
          }
        });
      }
    };
  
    return { showNotification };
  };