import { all, call, put, takeLatest } from "redux-saga/effects";
import get from "lodash/get";
import { request } from "utils/request";
import { userActions } from ".";
import { utilsActions } from "../utils";
import { DIALOG_TYPE } from "app/slice/utils/types";
import { USER } from "app/constants/API";
import { toast } from "react-toastify";
import { clearLocalStorageExceptKeys } from "store/LocalStorage";

function* signUp(action) {
  try {
    const res = yield call(
      request,
      "post",
      `${process.env.REACT_APP_BE_AUTH}/${USER.REGISTER}`,
      {
        firstName: get(action, "payload.firstName"),
        lastName: get(action, "payload.lastName"),
        phone: get(action, "payload.phone"),
        password: get(action, "payload.password"),
        code: get(action, "payload.code"),
      }
    );
    // yield put(userActions.signUpSucceeded(res));
    // yield put(userActions.signIn);
    // yield put(userActions.signInSuccessed(res.data));
    yield put(utilsActions.setDialogApp({ dialogType: DIALOG_TYPE.CLOSE }));
    // yield put(utilsActions.setDialogApp({ dialogType: DIALOG_TYPE.SIGN_IN }));
    // yield put(
    //   utilsActions.showSnackbar({
    //     message: `Please check your email ${res.sendToMail} to activate the account.`,
    //     variant: "success",
    //   })
    // );
    yield put(
      utilsActions.showSnackbar({
        message: `Login ${res.data.email} succeeded!`,
        variant: "success",
      })
    );
  } catch (error: any) {
    yield put(
      utilsActions.showSnackbar({
        message: get(error, "message", "Error Undefined"),
        variant: "error",
      })
    );
    yield put(userActions.signUpError(error.body));
  }
}

function* signIn(action) {
  try {
    const res = yield call(
      request,
      "post",
      `${process.env.REACT_APP_BE_AUTH}/${USER.LOGIN}`,
      {
        username: get(action, "payload.username"),
        password: get(action, "payload.password"),
      }
    );
    yield put(userActions.signInSuccessed(res.data));
    yield put(utilsActions.setDialogApp({ dialogType: DIALOG_TYPE.CLOSE }));
    yield put(
      utilsActions.showSnackbar({
        message: `Login ${res.data.email} succeeded!`,
        variant: "success",
      })
    );
  } catch (error: any) {
    yield put(userActions.signInError(error.body));
    yield put(
      utilsActions.showSnackbar({
        message: get(error, "message", "Error Undefined"),
        variant: "error",
      })
    );
  }
}

function* getMySeft(action) {
  try {
    const res = yield call(
      request,
      "get",
      `${process.env.REACT_APP_BACKEND_URL}/${USER.MY_SELF}`,
      null,
      get(action, "payload.token")
    );
    yield put(userActions.getMySeftSuccessed(res));
  } catch (error: any) {
    yield put(
      utilsActions.showSnackbar({
        message: get(error, "message", "Error Undefined"),
        variant: "error",
      })
    );
  }
}

function* signOut(action) {
  try {
    call(
      request,
      "post",
      `${process.env.REACT_APP_BACKEND_URL}/api/v1/users/logout`,
      null,
      get(action, "payload.token")
    );
    yield put(userActions.signOutSuccess());
    yield put(
      utilsActions.showSnackbar({
        message: `Logout succeeded!`,
        variant: "success",
      })
    );
  } catch (error: any) {
    yield put(userActions.clearLoading());
    yield put(
      utilsActions.showSnackbar({
        message: get(error, "message", "Error Undefined"),
        variant: "error",
      })
    );
  }
}

function* signOutWithoutReload(action) {
  try {
    call(
      request,
      "post",
      `${process.env.REACT_APP_BACKEND_URL}/api/v1/users/logout`,
      null,
      get(action, "payload.token")
    );
  } catch (error: any) {
    yield put(userActions.clearLoading());
  }
}

// Root saga
export default function* userSaga() {
  // if necessary, start multiple sagas at once with `all`
  yield all([
    takeLatest(userActions.signUp.type, signUp),
    takeLatest(userActions.signIn.type, signIn),
    takeLatest(userActions.signOut.type, signOut),
    takeLatest(userActions.signOutWithoutReload.type, signOutWithoutReload),
    takeLatest(userActions.getMySeft.type, getMySeft),
    // takeLatest(userActions.updateWallet.type, updateWallet),
    // takeLatest(userActions.logout.type, logout),
  ]);
}
