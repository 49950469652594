import { VariantType } from 'notistack';

export enum LOADING_STATE {
  EMPTY = 'EMPTY',
  LOADING = 'LOADING',
  LOADED = 'LOADED',
  LOADED_ERROR = 'LOADED_ERROR',
}

export enum DIALOG_TYPE {
  SIGN_UP = 'SIGN_UP',
  SIGN_IN = 'SIGN_IN',
  CLOSE = 'CLOSE',
  // ADD_PRODUCT = 'ADD_PRODUCT',
}

export type DIALOG_APP = {
  dialogType: DIALOG_TYPE;
  params?: any;
};

/* --- STATE --- */
export type Snackbar = {
  message: string;
  variant: VariantType;
};

export interface UtilsState {
  snackbar?: Snackbar;
  app: DIALOG_APP;
  // declare what you want in your Homepage state
}
