import React, { useEffect, useState } from 'react';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import styled from 'styled-components';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import MuiTextField from '@mui/material/TextField';
import OutlinedInput from '@mui/material/OutlinedInput';
import MuiFormControl from '@mui/material/FormControl';
import { LOADING_STATE } from 'app/constants/app';
import {
  IconButton,
  InputAdornment,
  InputLabel,
  Slide,
  Typography,
} from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { MAIL_REGEX, PASSWORD_REGEX, PHONE_REGEX } from 'utils/regexp';
import { useUserSlice } from 'app/slice/user';
import { useDispatch, useSelector } from 'react-redux';
import { userInfoSelector } from 'app/slice/user/selectors';
import { useUtilsSlice } from 'app/slice/utils';
import { DIALOG_TYPE } from 'app/slice/utils/types';
import { request } from 'utils/request';

const TextField = styled(MuiTextField)({
  '& .MuiTextField-root': {
    color: '#fff',
  },
  '& .MuiInputLabel-root': {
    color: '#fff',
  },
  '& .MuiInputBase-root': {
    color: '#fff',
    borderRadius: 10,
  },
  '& label.Mui-focused': {
    color: 'gold',
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: 'gold',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#59f81a',
    },
    '&:hover fieldset': {
      borderColor: '#B2BAC2',
    },
    '&.Mui-focused fieldset': {
      borderColor: 'gold',
    },
  },
});

const FormControl = styled(MuiFormControl)({
  '& .MuiTextField-root': {
    color: '#fff',
  },
  '& .MuiInputLabel-root': {
    color: '#fff',
  },
  '& .MuiInputBase-root': {
    color: '#fff',
    borderRadius: 10,
  },
  '& label.Mui-focused': {
    color: '#e3ba34',
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: 'gold',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#59f81a',
    },
    '&:hover fieldset': {
      borderColor: '#B2BAC2',
    },
    '&.Mui-focused fieldset': {
      borderColor: 'gold',
    },
  },
});

const enum STEP {
  SIGN_IN = 'SIGN_IN',
  FORGOT_PASSWORD = 'FORGOT_PASSWORD',
  CODE_VERIFY = 'CODE_VERIFY',
  SET_NEW_PASSWORD = 'SET_NEW_PASSWORD',
}

interface UserData {
  username: string;
  password: string;
  passwordConfirm: string;
}

interface ResetPassword {
  sessionId: string | null;
  userId: string | null;
  code: string | null;
  token: string | null;
}

export default function SignInDialog({
  onSignUp,
  onSignIn,
  theme,
  dialogType,
  dialogCloseAction,
}) {
  const dispatch = useDispatch();
  // const userInfo = useSelector(userInfoSelector);

  const { actions: userActions } = useUserSlice();
  const { actions: utilsAction } = useUtilsSlice();

  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);
  const [userData, setUserData] = useState<UserData>({
    username: '',
    password: '',
    passwordConfirm: '',
  });

  const [resetPassword, setResetPassword] = useState<ResetPassword>({
    sessionId: null,
    userId: null,
    code: null,
    token: null,
  });

  const [step, setStep] = useState<STEP>(STEP.SIGN_IN);
  const [usernameInvalid, setUsernameInvalid] = useState<string>('');
  const [passwordInvalid, setPasswordInvalid] = useState<string>('');
  const [passwordConfirmInvalid, setPasswordConfirmInvalid] =
    useState<string>('');

  const containerRef = React.useRef<HTMLElement>(null);
  // const userLoadingStatus = userInfo?.loading;

  // useEffect(() => {
  //   sessionStorage.clear();
  //   if (userLoadingStatus) {
  //     userLoadingStatus === LOADING_STATE.LOADED && dialogCloseAction();
  //   }
  // }, [userLoadingStatus]);

  const handleSetShowPassword = () => {
    setShowPassword(true);
  };

  const handleHiddenPassword = () => {
    setShowPassword(false);
  };

  const handleHiddenPasswordConfirm = () => {
    setShowPasswordConfirm(false);
  };

  const handleSetShowPasswordConfirm = () => {
    setShowPasswordConfirm(true);
  };

  const handleForgorPassword = () => {
    setStep(STEP.FORGOT_PASSWORD);
  };

  const handleSignIn = () => {
    if (
      isEmpty(usernameInvalid) &&
      isEmpty(passwordInvalid) &&
      !isEmpty(get(userData, 'username', '')) &&
      !isEmpty(get(userData, 'password', ''))
    ) {
      return dispatch(
        userActions.signIn({
          username: get(userData, 'username', ''),
          password: get(userData, 'password', ''),
        }),
      );
    }

    if (isEmpty(get(userData, 'username', ''))) {
      setUsernameInvalid('Username is not empty');
    }

    if (isEmpty(get(userData, 'password', ''))) {
      setPasswordInvalid('Password is not empty');
    }
  };

  const handleForgotPassword = async () => {
    if (isEmpty(get(userData, 'username', ''))) {
      return setUsernameInvalid('Username is not empty');
    }

    if (isEmpty(usernameInvalid) && !isEmpty(get(userData, 'username', ''))) {
      const result: any = await request(
        'post',
        `${process.env.REACT_APP_BACKEND_URL}/api/v1/users/forgot-password`,
        {
          username: get(userData, 'username'),
        },
      ).catch(error => {
        return dispatch(
          utilsAction.showSnackbar({
            message: get(error, 'message', 'Error Undefined'),
            variant: 'error',
          }),
        );
      });

      const { sessionId, userId, to } = result;
      if (sessionId && userId) {
        dispatch(
          utilsAction.showSnackbar({
            message: `Verification code sent to ${to}`,
            variant: 'success',
          }),
        );

        if (sessionId && userId) {
          setResetPassword({
            ...resetPassword,
            sessionId,
            userId,
          });

          setStep(STEP.CODE_VERIFY);
        }
      }
    }
  };

  const handleCodeVerify = async () => {
    if (!resetPassword.code) {
      return dispatch(
        utilsAction.showSnackbar({
          message: 'Please input verification code',
          variant: 'error',
        }),
      );
    }

    try {
      const result: any = await request(
        'post',
        `${process.env.REACT_APP_BACKEND_URL}/api/v1/users/${get(
          resetPassword,
          'userId',
        )}/reset-password`,
        {
          sessionId: get(resetPassword, 'sessionId'),
          code: get(resetPassword, 'code'),
        },
      );

      const { token } = result;
      if (token) {
        setResetPassword({
          ...resetPassword,
          token,
        });

        setStep(STEP.SET_NEW_PASSWORD);
      }
    } catch (error) {
      return dispatch(
        utilsAction.showSnackbar({
          message: get(error, 'message', 'Error Undefined'),
          variant: 'error',
        }),
      );
    }
  };

  const handleSetNewPassword = async () => {
    if (isEmpty(get(userData, 'password', ''))) {
      setPasswordInvalid('Password is not empty');
    } else if (isEmpty(get(userData, 'passwordConfirm', ''))) {
      setPasswordConfirmInvalid('Password Confirm is not empty');
    } else if (get(userData, 'password') !== get(userData, 'passwordConfirm')) {
      setPasswordConfirmInvalid(
        'Password and confirmation password do not match',
      );
    } else if (isEmpty(passwordInvalid) && isEmpty(passwordConfirmInvalid)) {
      try {
        await request(
          'put',
          `${process.env.REACT_APP_BACKEND_URL}/api/v1/users/${get(
            resetPassword,
            'userId',
          )}/reset-password`,
          {
            password: get(userData, 'password'),
          },
          get(resetPassword, 'token') as string,
        );

        dispatch(
          utilsAction.showSnackbar({
            message: 'Reset password success',
            variant: 'success',
          }),
        );

        setStep(STEP.SIGN_IN);
      } catch (error) {
        return dispatch(
          utilsAction.showSnackbar({
            message: get(error, 'message', 'Error Undefined'),
            variant: 'error',
          }),
        );
      }
    }
  };

  const signInSlide = () => (
    <Slide
      direction="right"
      in={step === STEP.SIGN_IN}
      container={containerRef.current}
    >
      <Box
        sx={{
          width: '100%',
        }}
      >
        <TextField
          error={!isEmpty(usernameInvalid)}
          helperText={usernameInvalid}
          label="Email or Phone"
          variant="outlined"
          value={userData.username}
          onKeyDown={(e)=> {
            if (e.key === 'Enter') {
              switch (step) {
                case STEP.SIGN_IN:
                  return handleSignIn();
                case STEP.FORGOT_PASSWORD:
                  return handleForgotPassword();
                case STEP.CODE_VERIFY:
                  return handleCodeVerify();
                case STEP.SET_NEW_PASSWORD:
                  return handleSetNewPassword();
              }
            }
          }}
          onChange={event => {
            const value = event.target.value.toLowerCase();
            setUserData({
              ...userData,
              username: value,
            });

            if (
              !isEmpty(value) &&
              !MAIL_REGEX.test(value) &&
              !PHONE_REGEX.test(value)
            ) {
              setUsernameInvalid('Username format incorrect');
            } else {
              setUsernameInvalid('');
            }
          }}
          sx={{
            marginTop: '28px',
            width: '100%',
          }}
        />
        <FormControl
          sx={{
            marginTop: '28px',
            width: '100%',
          }}
          variant="outlined"
        >
          <InputLabel htmlFor="outlined-adornment-password">
            Password
          </InputLabel>
          <OutlinedInput
            error={!isEmpty(passwordInvalid)}
            id="outlined-adornment-password"
            type={showPassword ? 'text' : 'password'}
            value={userData.password}
            onKeyDown={(e)=> {
              if (e.key === 'Enter') {
                switch (step) {
                  case STEP.SIGN_IN:
                    return handleSignIn();
                  case STEP.FORGOT_PASSWORD:
                    return handleForgotPassword();
                  case STEP.CODE_VERIFY:
                    return handleCodeVerify();
                  case STEP.SET_NEW_PASSWORD:
                    return handleSetNewPassword();
                }
              }
            }}
            onChange={event => {
              const value = event.target.value;
              setUserData({
                ...userData,
                password: value,
              });

              if (!isEmpty(value) && value.length < 8) {
                setPasswordInvalid('Password must be at least 8 characters');
              } else {
                setPasswordInvalid('');
              }
            }}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  sx={{
                    color: '#474747',
                  }}
                  aria-label="toggle password visibility"
                  onClick={
                    showPassword ? handleHiddenPassword : handleSetShowPassword
                  }
                  onMouseDown={() => {}}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
            label="Password"
          />
        </FormControl>
        <Typography
          sx={{
            display: !isEmpty(passwordInvalid) ? 'block' : 'none',
            color: 'red',
            fontStyle: 'italic',
          }}
        >
          {passwordInvalid}
        </Typography>
        <Button
          sx={{
            float: 'right',
            textTransform: 'capitalize',
            right: '16px',
            bottom: '0px',
            marginTop: '16px',
            fontSize: 12,
            fontWeight: 700,
            color: 'white',
          }}
          onClick={() => {
            handleForgorPassword();
          }}
          variant="text"
        >
          Quên mật khẩu
        </Button>
      </Box>
    </Slide>
  );

  const forgotPasswordSlide = () => (
    <Slide
      direction="left"
      in={step === STEP.FORGOT_PASSWORD}
      container={containerRef.current}
    >
      <Box
        sx={{
          width: '100%',
        }}
      >
        <TextField
          error={!isEmpty(usernameInvalid)}
          helperText={usernameInvalid}
          label="Email or Phone"
          variant="outlined"
          value={userData.username}
          onChange={event => {
            const value = event.target.value.toLowerCase();
            setUserData({
              ...userData,
              username: value,
            });

            if (
              !isEmpty(value) &&
              !MAIL_REGEX.test(value) &&
              !PHONE_REGEX.test(value)
            ) {
              setUsernameInvalid('Username format incorrect');
            } else {
              setUsernameInvalid('');
            }
          }}
          sx={{
            marginTop: '28px',
            width: '100%',
          }}
        />
      </Box>
    </Slide>
  );

  const verifyCodeSlide = () => (
    <Slide
      direction="left"
      in={step === STEP.CODE_VERIFY}
      container={containerRef.current}
    >
      <Box
        sx={{
          display: step === STEP.CODE_VERIFY ? 'block' : 'none',
          width: '100%',
        }}
      >
        <Typography sx={{ color: '#d1d1d184' }}>
          {`Please check email inbox and input the Verification Code`}
        </Typography>
        <TextField
          error={!resetPassword.code}
          helperText={!resetPassword.code ? 'Incorrect, code is 6 digit' : ''}
          label="Verification Code"
          variant="outlined"
          onChange={event => {
            const value = event.target.value;
            if (/^[0-9]{6,6}$/g.test(value)) {
              setResetPassword({
                ...resetPassword,
                code: value,
              });
            }
          }}
          sx={{
            marginTop: '28px',
            width: '100%',
          }}
        />
      </Box>
    </Slide>
  );

  const setNewPasswordSlide = () => (
    <Slide
      direction="left"
      in={step === STEP.SET_NEW_PASSWORD}
      container={containerRef.current}
    >
      <Box
        sx={{
          display: step === STEP.SET_NEW_PASSWORD ? 'block' : 'none',
          width: '100%',
        }}
      >
        <FormControl
          sx={{
            marginTop: '28px',
            width: '100%',
            borderRadius: 8,
          }}
          variant="outlined"
        >
          <InputLabel htmlFor="outlined-adornment-password">
            Password
          </InputLabel>
          <OutlinedInput
            error={!isEmpty(passwordInvalid)}
            id="outlined-adornment-password"
            type={showPassword ? 'text' : 'password'}
            value={userData.password}
            onChange={event => {
              const value = event.target.value;
              setUserData({
                ...userData,
                password: event.target.value,
              });

              if (!isEmpty(value) && PASSWORD_REGEX.test(value)) {
                setPasswordInvalid(
                  'Password must be at least 8 characters including one uppercase letter, one special character and alphanumeric characters',
                );
              } else {
                setPasswordInvalid('');
              }
            }}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  sx={{
                    color: '#474747',
                  }}
                  aria-label="toggle password visibility"
                  onClick={
                    showPassword ? handleHiddenPassword : handleSetShowPassword
                  }
                  onMouseDown={() => {}}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
            label="Password"
          />
        </FormControl>

        <FormControl
          sx={{
            marginTop: '28px',
            width: '100%',
            borderRadius: 8,
          }}
          variant="outlined"
        >
          <InputLabel htmlFor="outlined-adornment-password">
            Password Confirm
          </InputLabel>
          <OutlinedInput
            error={!isEmpty(passwordInvalid)}
            id="outlined-adornment-password"
            type={showPasswordConfirm ? 'text' : 'password'}
            value={userData.passwordConfirm}
            onChange={event => {
              const value = event.target.value;
              setPasswordConfirmInvalid('');
              setUserData({
                ...userData,
                passwordConfirm: value,
              });
            }}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  sx={{
                    color: '#474747',
                  }}
                  aria-label="toggle password visibility"
                  onClick={
                    showPasswordConfirm
                      ? handleHiddenPasswordConfirm
                      : handleSetShowPasswordConfirm
                  }
                  onMouseDown={() => {}}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
            label="Password Confirm"
          />
        </FormControl>

        <Typography
          sx={{
            display:
              !isEmpty(passwordInvalid) || !isEmpty(passwordConfirmInvalid)
                ? 'block'
                : 'none',
            color: 'red',
            fontStyle: 'italic',
          }}
        >
          {passwordInvalid || passwordConfirmInvalid}
        </Typography>
      </Box>
    </Slide>
  );

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        marginTop: '30px',
        marginBottom: '30px',
        marginLeft: '60px',
        marginRight: '60px',
        // height: 'calc(100% - 60px)',
        height: '100%',
        // position: 'relative',
        [theme.breakpoints.down('sm')]: {
          marginTop: '10px',
          height: 'calc(100% - 40px)',
        },
      }}
    >
      <Box
        sx={{
          backgroundColor: '#0f0f0f',
          display: 'inline-flex',
          borderRadius: 2,
          mb: 2,
        }}
      >
        <Button
          sx={{
            paddingTop: '8px',
            paddingBottom: '8px',
            paddingLeft: '18px',
            paddingRight: '18px',

            marginTop: '8px',
            marginBottom: '8px',
            marginLeft: '12px',
            marginRight: '6px',

            color: 'white',
            ...(dialogType === DIALOG_TYPE.SIGN_UP && {
              backgroundColor: '#26262b',
              fontWeight: 700,
            }),
            fontSize: 12,
            borderRadius: 2,
            textTransform: 'capitalize',
          }}
          variant="text"
          onClick={onSignUp}
        >
          ĐĂNG KÝ
        </Button>
        <Button
          sx={{
            paddingTop: '8px',
            paddingBottom: '8px',
            paddingLeft: '18px',
            paddingRight: '18px',

            marginTop: '8px',
            marginBottom: '8px',
            marginLeft: '6px',
            marginRight: '12px',

            color: 'white',
            ...(dialogType === DIALOG_TYPE.SIGN_IN && {
              backgroundColor: '#26262b',
              fontWeight: 700,
            }),
            fontSize: 12,
            borderRadius: 2,
            textTransform: 'capitalize',
          }}
          variant="text"
          onClick={() => {
            setStep(STEP.SIGN_IN);
            onSignIn();
          }}
        >
          ĐĂNG NHẬP
        </Button>
      </Box>

      <Box
        className="scrollbar-small"
        ref={containerRef}
        sx={{
          width: '100%',
          maxHeight: '80%',
          height: 'auto',
          overflowX: 'hidden',
          overflowY: 'auto',
          mb: 2,
        }}
      >
        {step === STEP.SIGN_IN ? (
          signInSlide()
        ) : step === STEP.FORGOT_PASSWORD ? (
          forgotPasswordSlide()
        ) : step === STEP.CODE_VERIFY ? (
          verifyCodeSlide()
        ) : step === STEP.SET_NEW_PASSWORD ? (
          setNewPasswordSlide()
        ) : (
          <></>
        )}
      <Box
        sx={{
          mt: 'auto',
          mb: 10,
          width: '100%',
          bottom: 2,
        }}
      >
        <Button
          sx={{
            // mt: 'auto',
            padding: '8px',
            width: '100%',
            color: 'black',
            borderRadius: 8,
            backgroundColor: '#fdcf39',
            '&:hover': {
              backgroundColor: '#c4a337',
              color: 'black',
            },
            fontSize: 12,
            fontWeight: 700,
            textTransform: 'capitalize',
          }}
          variant="text"
          onClick={() => {
            switch (step) {
              case STEP.SIGN_IN:
                return handleSignIn();
              case STEP.FORGOT_PASSWORD:
                return handleForgotPassword();
              case STEP.CODE_VERIFY:
                return handleCodeVerify();
              case STEP.SET_NEW_PASSWORD:
                return handleSetNewPassword();
            }
          }}
        >
          {step === STEP.SIGN_IN
            ? 'ĐĂNG NHẬP'
            : step === STEP.FORGOT_PASSWORD
            ? 'Request new password'
            : step === STEP.CODE_VERIFY
            ? 'Verify'
            : step === STEP.SET_NEW_PASSWORD
            ? 'Set new password'
            : 'OK'}
        </Button>
      </Box>
      </Box>
    </Box>
  );
}
