import React from 'react';
import { MdClose } from 'react-icons/md';

interface LoadingProps {
  children?: React.ReactNode;
  isOpen?: boolean;
}

const Loading: React.FC<LoadingProps> = ({
  isOpen,
}) => {
  return (
    <div
      className={`dialog backdrop-blur-sm fixed inset-0 flex items-center justify-center ${
        isOpen ? 'visible' : 'hidden'
      }`}
      // onKeyDown={(e: any) => {
      //   if (e.keyCode === 27) {
      //     onClose();
      //   }
      // }}
    >
      <div
        className={`
            fixed 
            drop-shadow-md 
            bg-transparent
            top-[50%] 
            left-[50%] 
            max-h-full 
            h-auto
            w-auto
            translate-x-[-50%]
            translate-y-[-50%]
            rounded-2xl
            overflow-hidden
            focus:outline-none
        `}
      >
        <div className='w-[100px] h-[100px] flex justify-center items-center bg-neutral-100/10'>
          <div className='spin w-[50px] h-[50px]'></div>
        </div>
      </div>
    </div>
  );
};

export default Loading;
