import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit'; // Importing from `utils` makes them more type-safe ✅
import { useInjectReducer } from 'utils/redux-injectors';
import { DIALOG_APP, DIALOG_TYPE, Snackbar, UtilsState } from '../utils/types';

// The initial state of the user
export const initialState: UtilsState = {
  snackbar: {
    message: '',
    variant: 'default',
  },
  app: {
    dialogType: DIALOG_TYPE.CLOSE,
  },
};

const slice = createSlice({
  name: 'utils' as never,
  initialState,
  reducers: {
    showSnackbar(state, action: PayloadAction<Snackbar>) {
      // Here we say lets change the username in my user state when changeUsername actions fires
      // Type-safe: It will expect `string` when firing the action. ✅
      state.snackbar = action.payload;
    },
    setDialogApp(state, action: PayloadAction<DIALOG_APP>) {
      state.app.dialogType = action.payload.dialogType;
      state.app.params = action.payload.params || {};
    },
  },
});

/**
 * `actions` will be used to trigger change in the state from where ever you want
 */
export const { actions: utilsActions } = slice;

export const useUtilsSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer as any });
  return { actions: slice.actions };
};
