import * as React from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import AccountCircle from "@mui/icons-material/AccountCircle";
import IconButton from "@mui/material/IconButton";

import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { userInfoSelector } from 'app/slice/user/selectors';
import { useUtilsSlice } from 'app/slice/utils';
import { appSelector } from 'app/slice/utils/selectors';
import { useUserSlice } from 'app/slice/user';
import { DIALOG_TYPE } from 'app/slice/utils/types';

export default function MenuListComposition() {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { actions: utilsAction } = useUtilsSlice();
  const { actions: userAction } = useUserSlice();
  const userInfo = useSelector(userInfoSelector);
  const { dialogType } = useSelector(appSelector);
  const handleClose = () => {
    setAnchorEl(null);
  };
  const onSignIn = () => {
    dispatch(utilsAction.setDialogApp({ dialogType: DIALOG_TYPE.SIGN_IN }));
  };
  const onSignOut = () => {
    dispatch(userAction.signOut({}));
  };
  const redirectMyAccount = () => {
    navigate('/account')
  };
  if(userInfo?.userId) {
      return (
        <div>
          <IconButton
            size="large"
            color="inherit"
            id="demo-positioned-button"
            aria-controls={open ? 'demo-positioned-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={handleClick}
          >
            <AccountCircle /> 
          </IconButton>
          <Menu
            id="demo-positioned-menu"
            aria-labelledby="demo-positioned-button"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
          >
            {/* <MenuItem onClick={handleClose}>Profile</MenuItem> */}
            <MenuItem onClick={redirectMyAccount}>Tài khoản của tôi</MenuItem>
            <MenuItem onClick={onSignOut}>Đăng xuất</MenuItem>
          </Menu>
        </div>
      );
  } else {
    return(
        <Menu
            id="demo-positioned-menu"
            aria-labelledby="demo-positioned-button"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
          >
            <MenuItem onClick={onSignIn}>Đăng nhập</MenuItem>
          </Menu>
    )
  }
}
