'use client'
import React, { useState, useEffect, useRef } from "react";
import {
  Container,
  TextField,
  Typography,
  Box,
  Button,
  Tooltip,
  IconButton,
  Grid,
} from "@mui/material";
import T9Keyboard from "../T9Keyboard/t9keyboard"; // Đường dẫn đến file chứa T9Keyboard
import Draggable from "react-draggable";
// import { baselightTheme } from "../theme/DefaultColors";
import { useAuth } from "../provider/ContextProvider";
import MusicNoteIcon from "@mui/icons-material/MusicNote";
import MusicOffIcon from "@mui/icons-material/MusicOff";
import MicIcon from "@mui/icons-material/Mic";
import MicOffIcon from "@mui/icons-material/MicOff";
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import VolumeOffIcon from '@mui/icons-material/VolumeOff';
import PhonePausedIcon from '@mui/icons-material/PhonePaused';
import PhoneInTalkIcon from '@mui/icons-material/PhoneInTalk';

import keyTapSrc from "assets/sound/punchy-taps-ui-10-183906.mp3";

const PhoneApp: React.FC = () => {
  const [input, setInput] = useState<string>("");
  const [calling, setCalling] = useState<any>(false);
  const [soundEffect, setSoundEffect] = useState<any>(true);
  const textFieldRef = useRef<HTMLInputElement>(null);

  const handleInput = (key: { number: string; letters: string }) => {
    setInput((prev) => prev + key.number);
    if (soundEffect) {
      const taptone = new Audio(keyTapSrc);
      taptone.play();
    }
  };

  const handleTextFieldChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setInput(event.target.value);
    if (soundEffect) {
      const taptone = new Audio(keyTapSrc);
      taptone.play();
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      event.preventDefault(); // Ngăn chặn hành động mặc định của phím Enter
      handleMakeCall();
    }
  };
  const {
    makeCall,
    endCall,
    callState,
    isOpenPhone,
    isDisableMic,
    isMuteAudio,
    isHold,
    handleHold,
    handleMuteAudio,
    handleDisableMic,
  } = useAuth();

  // Focus vào TextField khi component mount
  useEffect(() => {
    if (textFieldRef.current) {
      textFieldRef.current.focus();
    }
  }, []);

  useEffect(() => {
    if (callState === "hangup" || !callState) {
      setCalling(false);
    } else {
      setCalling(true);
    }
  }, [callState]);

  const handleMakeCall = () => {
    if (input && !calling) {
      makeCall(input, "");
    }
  };
  const handleEndCall = () => {
    endCall();
  };

  return (
    <Draggable handle=".handle">
      <Container
        sx={{
          width: 260,
          p: 2,
          display: isOpenPhone ? "flex" : "none",
          flexDirection: "column",
          gap: 1,
          border: 1,
          borderColor: "divider",
          borderRadius: 2,
          backgroundColor: "white",
          position: "fixed",
          zIndex: 2,
          bottom: 2,
          right: 2,
        }}
      >
        <Tooltip
          title="có thể kéo thả SIP phone tới vị trí mong muốn"
          placement="right"
        >
          <Typography
            className="handle"
            variant="h6"
            gutterBottom
            sx={{
              width: "100%",
              textAlign: "center",
              cursor: "move",
              color: "primary.main",
            }}
          >
            SIP Phone
          </Typography>
        </Tooltip>
        <TextField
          variant="standard"
          fullWidth
          value={input}
          ref={textFieldRef}
          onKeyDown={handleKeyDown}
          onChange={handleTextFieldChange}
          InputProps={{
            disableUnderline: true,
          }}
          sx={{ bgcolor: "#a4d8b0", borderRadius: 0.5 }}
        />
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 2,
          }}
        >
          <Tooltip title={soundEffect ? "Tắt âm bàn phím" : "Bật âm bàn phím"}>
            <IconButton onClick={() => setSoundEffect(!soundEffect)}>
              {soundEffect ? (
                <MusicNoteIcon fontSize="small" />
              ) : (
                <MusicOffIcon fontSize="small" />
              )}
            </IconButton>
          </Tooltip>
          <Tooltip title={!isDisableMic ? "Tắt Mic" : "Bật Mic"}>
            <IconButton onClick={handleDisableMic}>
              {isDisableMic ? (
                <MicOffIcon fontSize="small" />
              ) : (
                <MicIcon
                  fontSize="small"
                  color={callState === "answered" ? "warning" : "action"}
                />
              )}
            </IconButton>
          </Tooltip>
          <Tooltip title={!isHold ? "Giữ cuộc gọi" : "Tiếp tục cuộc gọi"}>
            <IconButton onClick={handleHold}>
              {isHold ? (
                <PhonePausedIcon fontSize="small" color="warning" />
              ) : (
                <PhoneInTalkIcon
                  fontSize="small"
                  color={callState === "answered" ? "warning" : "action"}
                />
              )}
            </IconButton>
          </Tooltip>
        </Box>
        <T9Keyboard onInput={handleInput} />
        <Grid container spacing={1.5}>
          <Grid item xs={8}>
            <Button
              variant="contained"
              color="success"
              fullWidth
              onClick={handleMakeCall}
              sx={{
                display: !calling ? "flex" : "none",
              }}
            >
              Gọi
            </Button>
            <Button
              variant="contained"
              color="warning"
              fullWidth
              onClick={handleEndCall}
              sx={{
                display: calling ? "flex" : "none",
              }}
            >
              Gác máy
            </Button>
          </Grid>
          <Grid item xs={4}>
            <Button
              variant="contained"
              color="error"
              onClick={() => setInput("")}
              sx={{
                width: "100%",
              }}
            >
              Xoá
            </Button>
          </Grid>
        </Grid>
      </Container>
    </Draggable>
  );
};

export default PhoneApp;
