'use client'
import React, { useEffect } from 'react';
import { Button, Grid, Typography } from '@mui/material';

type T9Key = {
  number: string;
  letters: string;
};

interface T9KeyboardProps {
  onInput: (key: T9Key) => void;
}

const T9Keyboard: React.FC<T9KeyboardProps> = ({ onInput }) => {
  const keys: T9Key[] = [
    { number: '1', letters: '' },
    { number: '2', letters: 'ABC' },
    { number: '3', letters: 'DEF' },
    { number: '4', letters: 'GHI' },
    { number: '5', letters: 'JKL' },
    { number: '6', letters: 'MNO' },
    { number: '7', letters: 'PQRS' },
    { number: '8', letters: 'TUV' },
    { number: '9', letters: 'WXYZ' },
    { number: '*', letters: '' },
    { number: '0', letters: ' ' },
    { number: '#', letters: '' },
  ];

  const handleClick = (key: T9Key) => {
    onInput(key);
  };

  return (
    <Grid container spacing={1.5}>
      {keys.map((key) => (
        <Grid item xs={4} key={key.number}>
          <Button
            color="primary"
            variant="contained"
            onClick={() => handleClick(key)}
            fullWidth
          >
            <Typography variant="h6">{key.number}</Typography>
            <Typography variant="caption">{key.letters}</Typography>
          </Button>
        </Grid>
      ))}
    </Grid>
  );
};

export default T9Keyboard;
