import React, { useEffect, useState } from "react";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDialog from "@mui/material/Dialog";
import MuiPaper from "@mui/material/Paper";
import DialogContent from "@mui/material/DialogContent";
import LoginBanner from "assets/images/logo/vinacom360.jpg";
import LoginMobile from "assets/images/logo/vinacom360.jpg";
import SignupBanner from "assets/images/logo/vinacom360.jpg";
import SignupMobile from "assets/images/logo/vinacom360.jpg";
import { LOADING_STATE } from "app/constants/app";
import { IconButton, useMediaQuery } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { LoadingIndicator } from "../../LoadingIndicator";
import { useDispatch, useSelector } from "react-redux";
import { userInfoSelector } from "app/slice/user/selectors";
import { useUtilsSlice } from "app/slice/utils";
import { appSelector } from "app/slice/utils/selectors";
import { DIALOG_TYPE } from "app/slice/utils/types";
import SignInDialog from "./SignIn";
import SignUpDialog from "./SignUp";

export default function AuthDialog({ dialogCloseAction }) {
  const dispatch = useDispatch();
  const theme = useTheme();
  // const userInfo = useSelector(userInfoSelector);
  const { dialogType } = useSelector(appSelector);

  const { actions: utilsAction } = useUtilsSlice();

  const [loading, setLoading] = useState(false);
  // const userLoadingStatus = userInfo?.loading;

  // useEffect(() => {
  //   sessionStorage.clear();
  //   if (userLoadingStatus) {
  //     setLoading(userLoadingStatus === LOADING_STATE.LOADING);
  //     userLoadingStatus === LOADING_STATE.LOADED && dialogCloseAction();
  //   }
  // }, [userLoadingStatus]);

  const onSignIn = () => {
    dispatch(utilsAction.setDialogApp({ dialogType: DIALOG_TYPE.SIGN_IN }));
  };

  const onSignUp = () => {
    dispatch(utilsAction.setDialogApp({ dialogType: DIALOG_TYPE.SIGN_UP }));
  };

  return (
    <MuiDialog
      disableEnforceFocus
      // fullScreen={!useMediaQuery(theme.breakpoints.up("md"))}
      // fullWidth={true}
      maxWidth="md"
      open={dialogType !== DIALOG_TYPE.CLOSE}
      onClose={dialogCloseAction}
      scroll="paper"
      PaperProps={{
        sx: {
          borderRadius: 2,
          bgcolor: "#0b0b0b8c",
          margin: 1,
          height: "auto",
          [theme.breakpoints.down("sm")]: {
            // height: "100vh",
          },
          overflow: "hidden",
        },
      }}
    >
      <DialogContent
        sx={{
          padding: 0,
          overflow: "hidden",
        }}
      >
        {loading && <LoadingIndicator />}
        <Box
          sx={{
            display: "flex",
            alignItems: 'stretch',
            flexDirection: "row",
            [theme.breakpoints.down("sm")]: {
              flexDirection: "column",
            },
            width: "100%",
            height: "470px",
          }}
        >
          <IconButton
            className="rotateZ"
            sx={{
              p: 1,
              position: "absolute",
              right: "32px",
              top: "22px",
              color: "white",
              backgroundColor: "#63636735",
            }}
            aria-label="toggle password visibility"
            onClick={dialogCloseAction}
            onMouseDown={() => {}}
            edge="end"
          >
            <CloseIcon fontSize="small" />
          </IconButton>
          <Box
            sx={{
              background:
                "linear-gradient(193.59deg,#1f1f79 7.22%,rgba(43,43,45,0) 59.74%)",
              flex: 45 / 100,
              [theme.breakpoints.up("sm")]: {
                // flex: 10 / 100,
                display: "none",
              },
            }}
          >
            <MuiPaper
              sx={{
                height: "auto",
                background: `url("${
                  dialogType === DIALOG_TYPE.SIGN_UP
                    ? SignupMobile
                    : LoginMobile
                }")`,
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
              }}
            />
          </Box>
          <Box
            sx={{
              flex: 30 / 50,
              [theme.breakpoints.down("sm")]: {
                flex: 30 / 100,
                display: "none",
              },
            }}
          >
            <img
              src={
                dialogType === DIALOG_TYPE.SIGN_UP ? SignupBanner : LoginBanner
              }
              alt=""
              width={"auto"}
              height={"auto"}
            />
          </Box>
          <Box
            sx={{
              flex: 55 / 100,
              height: 'auto',
              [theme.breakpoints.down("sm")]: {
                flex: 95 / 100,
              },
            }}
          >
            {dialogType === DIALOG_TYPE.SIGN_UP ? (
              <SignUpDialog
                onSignUp={onSignUp}
                onSignIn={onSignIn}
                setLoading={setLoading}
                theme={theme}
                dialogType={dialogType}
                dialogCloseAction={dialogCloseAction}
              />
            ) : (
              <SignInDialog
                onSignUp={onSignUp}
                onSignIn={onSignIn}
                theme={theme}
                dialogType={dialogType}
                dialogCloseAction={dialogCloseAction}
              />
            )}
          </Box>
        </Box>
      </DialogContent>
    </MuiDialog>
  );
}
