/**
 *
 * App
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 */

import * as React from "react";
import { Helmet } from "react-helmet-async";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { GlobalStyle } from "styles/global-styles";
import { HomePage } from "./pages/HomePage/Loadable";
import { CustomersPage } from "./pages/CustomersPage/Loadable";
import { ProgressPage } from "./pages/ProgressPage/Loadable";
import { CdrPage } from "./pages/CdrPage";
import { AccountPage } from "./pages/Account/Loadable";
import { WorkspacePage } from "./pages/WorkSpace/Loadable";
import { UsersPage } from "./pages/UsersPage/Loadable";
import { UserPage } from "./pages/UserPage/Loadable";
import { LinkCardPage } from "./pages/LinkCard/Loadable";
import { LinkCustomerPage } from "./pages/LinkCustomer/Loadable";
import { CompanyPage } from "./pages/CompanyPage/Loadable";
import { TasksPage } from "./pages/TasksPage/Loadable";
import { AutocallsPage } from "./pages/AutocallsPage/Loadable";
import { MonitorPage } from "./pages/MonitorPage/Loadable";
import { FileManagerPage } from "./pages/FilePage/Loadable";
import { NotFoundPage } from "./components/NotFoundPage/Loadable";
import { useTranslation } from "react-i18next";
import { SnackbarProvider, VariantType, enqueueSnackbar } from "notistack";
import { useDispatch, useSelector } from 'react-redux';
import { useUtilsSlice } from './slice/utils';
import { snackbarSelector } from './slice/utils/selectors';
import Sidebar from "./components/Sidebar/Sidebar";
import AppBarWrapper from "./components/AppBar/AppWrapper";
import { constans } from "./pages/CONSTANS";
import "./globals.css";

export function App() {
  const { i18n } = useTranslation();
  const dispatch = useDispatch();
  const { actions: utilsAction } = useUtilsSlice();
  const snackbarData = useSelector(snackbarSelector);
  const changeLanguage = (lng: string) => i18n.changeLanguage(lng);
  const showSnackbar = (messages, variant: VariantType) => {
    enqueueSnackbar(messages, {
      variant,
      onExit: () => {
        dispatch(utilsAction.showSnackbar({ message: '', variant: 'default' }));
      },
    });
  };

  React.useEffect(() => {
    if (snackbarData.message) {
      showSnackbar(snackbarData.message, snackbarData.variant);
    }
  }, [snackbarData]);

  return (
    <BrowserRouter>
      <Helmet
        titleTemplate="%s - VINACOM360 CRM"
        defaultTitle="VINACOM360 CRM"
        htmlAttributes={{ lang: i18n.language }}
      >
        <meta
          name="description"
          content="Produced by ThienDev - thientt.dev@gmail.com"
        />
      </Helmet>
      <SnackbarProvider>
        <AppBarWrapper changeLanguage={changeLanguage}>
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/customers" element={<CustomersPage />} />
            <Route path="/customers/progress" element={<ProgressPage />} />
            <Route path="/task" element={<TasksPage />} />
            <Route path="/autocall" element={<AutocallsPage />} />
            <Route path="/customers/:path" element={<WorkspacePage />} />
            <Route path="/cdr" element={<CdrPage />} />
            <Route path="/cdr/:path" element={<CdrPage />} />
            <Route path="/company" element={<CompanyPage />} />
            <Route path="/account" element={<AccountPage />} />
            <Route path="/users" element={<UsersPage />} />
            <Route path="/users/:path" element={<UserPage />} />
            <Route path="/monitor" element={<MonitorPage />} />
            <Route path="/filemanager" element={<FileManagerPage />} />
            <Route path="/filemanager/:path" element={<FileManagerPage />} />
            <Route path="/account/link-card" element={<LinkCardPage />} />
            <Route
              path="/account/link-card/:path"
              element={<LinkCustomerPage />}
            />
            <Route path="*" element={<NotFoundPage />} />
          </Routes>
        </AppBarWrapper>
      </SnackbarProvider>
      <GlobalStyle />
    </BrowserRouter>
  );
}
