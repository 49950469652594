import DOMPurify from 'dompurify';
/* jodit config - rich text editor */
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import { request } from 'utils/request';
import { useUtilsSlice } from 'app/slice/utils';
// import s3Client from 'utils/s3';
import get from 'lodash/get';
import set from 'lodash/set';
import iconImage from 'assets/images/image.png';
import iconCopy from 'assets/images/copy.png';

const copyStringToClipboard = function (str) {
  const el: any = document.createElement('textarea');
  el.value = str;
  el.setAttribute('readonly', '');
  el.style = { position: 'absolute', left: '-9999px' };
  document.body.appendChild(el);
  el.select();
  document.execCommand('copy');
  document.body.removeChild(el);
};

const facilityMergeFields = [
  'FacilityNumber',
  'FacilityName',
  'Address',
  'MapCategory',
  'Latitude',
  'Longitude',
  'ReceivingPlant',
  'TrunkLine',
  'SiteElevation',
];
const inspectionMergeFields = ['InspectionCompleteDate', 'InspectionEventType'];
const createOptionGroupElement = (mergeFields, optionGrouplabel) => {
  let optionGroupElement = document.createElement('optgroup');
  optionGroupElement.setAttribute('label', optionGrouplabel);
  for (let index = 0; index < mergeFields?.length; index++) {
    let optionElement = document.createElement('option');
    optionElement.setAttribute('class', 'merge-field-select-option');
    optionElement.setAttribute('value', mergeFields[index]);
    optionElement.text = mergeFields[index];
    optionGroupElement.appendChild(optionElement);
  }
  return optionGroupElement;
};
const buttons = [
  'undo',
  'redo',
  '|',
  'bold',
  'strikethrough',
  'underline',
  'italic',
  '|',
  'superscript',
  'subscript',
  '|',
  'align',
  '|',
  'ul',
  'ol',
  'outdent',
  'indent',
  '|',
  'font',
  'fontsize',
  'brush',
  'paragraph',
  '|',
  // 'image',
  'link',
  'table',
  '|',
  'hr',
  'eraser',
  'copyformat',
  '|',
  'fullsize',
  'selectall',
  'print',
  '|',
  'source',
  '|',
  // {
  //   name: 'insertMergeField',
  //   tooltip: 'Insert Merge Field',
  //   iconURL: 'images/merge.png',
  //   popup: (editor, current, self, close) => {
  //     function onSelected(e) {
  //       let mergeField = e.target.value;
  //       if (mergeField) {
  //         console.log(mergeField);
  //         editor.selection.insertNode(
  //           editor.create.inside.fromHTML('{{' + mergeField + '}}'),
  //         );
  //       }
  //     }
  //     let divElement = editor.create.div('merge-field-popup');

  //     let labelElement: any = document.createElement('label');
  //     labelElement.setAttribute('class', 'merge-field-label');
  //     labelElement.text = 'Merge field: ';
  //     divElement.appendChild(labelElement);

  //     let selectElement = document.createElement('select');
  //     selectElement.setAttribute('class', 'merge-field-select');
  //     selectElement.appendChild(
  //       createOptionGroupElement(facilityMergeFields, 'Facility'),
  //     );
  //     selectElement.appendChild(
  //       createOptionGroupElement(inspectionMergeFields, 'Inspection'),
  //     );
  //     selectElement.onchange = onSelected;
  //     divElement.appendChild(selectElement);

  //     console.log(divElement);
  //     return divElement;
  //   },
  // },
  {
    name: 'copyContent',
    tooltip: 'Copy HTML to Clipboard',
    iconURL: iconCopy,
    exec: function (editor) {
      let html = editor.value;
      copyStringToClipboard(html);
    },
  },
  {
    name: 'up ảnh',
    iconURL: iconImage, // URL của biểu tượng nút
    tooltip: 'Chèn ảnh', // Mô tả khi di chuột qua nút
    exec: function (editor) {
      const input: any = document.createElement('input');
      input.setAttribute('type', 'file');
      input.setAttribute('accept', 'image/*');
      input.onchange = function () {
        const file = input.files[0];
        // if (file) {
        //   const reader = new FileReader();
        //   reader.readAsDataURL(file);
        //   reader.onload = async (e: any) => {
        //     const uploadInfo = await s3Client.uploadFile(
        //       `phuonglinh/detail/${Date.now()}-${file.name}`,
        //       file,
        //     );
        //     const url = get(uploadInfo, 'Location');
        //     editor.selection.insertHTML(
        //       `<img src="${url}" width="100%" height="auto" style={{ marginLeft: 'auto', marginRight: 'auto', borderRadius: '5px' }} alt=""/>`,
        //     );
        //   };
        // }
        if (file) {
          const reader = new FileReader();
          reader.onload = function (e: any) {
            const base64Data = e.target.result;
            editor.selection.insertHTML(
              `<img src="${base64Data}" width="100%" height="auto" style={{ marginLeft: 'auto', marginRight: 'auto', borderRadius: '5px' }} alt=""/>`,
            );
          };
          reader.readAsDataURL(file);
        }
      };
      input.click();
    },
  },
];

export const editorConfigJodit: any = {
  readonly: false,
  toolbar: true,
  spellcheck: true,
  language: 'en',
  toolbarButtonSize: 'medium',
  toolbarAdaptive: false,
  showCharsCounter: true,
  showWordsCounter: true,
  showXPathInStatusbar: false,
  askBeforePasteHTML: true,
  askBeforePasteFromWord: true,
  defaultActionOnPaste: 'insert_clear_html',
  buttons: buttons,
  uploader: {
    insertImageAsBase64URI: true,
  },
  width: 800,
  height: 542,
};

export const createMarkup = (html: any) => {
  return {
    __html: DOMPurify.sanitize(html),
  };
};
